import React from "react";
import {Outlet} from "react-router-dom"

import Header from "../../../common/header";
import Menu from "../../../common/menu";
import Footer from "../../../common/footer";

import './dictionaries.scss';

const Dictionaries = () => {
  return (
    <>
      <Header/>
      <div className='layout'>
        <Menu/>
        <div className='dictionaries'>
          <Outlet/>
        </div>
      </div>
      <Footer />
    </>
  )
};

export default Dictionaries
