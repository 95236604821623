import React, {useEffect} from "react";
import moment from "moment";
import {useTranslation} from "react-i18next";

import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import CheckIcon from '@mui/icons-material/Check';

import {useLazyGetDictionaryLanguagesQuery} from "../../../../../api/localization";
import {useUser, useUpdateUserInfoMutation} from "../../../../../api/auth";
import {COLORS} from "../../../../../config/constants";

type Props = {
    anchorEl: HTMLElement | null
    handleCloseMenu(): void
}

const LanguageMenu = ({ anchorEl, handleCloseMenu } : Props) => {

  const { t } = useTranslation()

  const { i18n } = useTranslation();

  const [ localizationGetLanguagesRequest, { data: languages } ] = useLazyGetDictionaryLanguagesQuery();

  const [ updateUserInfo ] = useUpdateUserInfoMutation()

  const user = useUser();

  const lang = user.lang;

  useEffect(() => {
    localizationGetLanguagesRequest('');
  }, []);

  React.useEffect(() => {
    const savedLocale = localStorage.getItem('locale') || 'en';
    i18n.changeLanguage(savedLocale.toLowerCase());
    moment.locale(savedLocale);
  }, [ i18n ]);

  const handleChangeLanguage = async (value: string) => {
    await updateUserInfo({
      'lang': value,
    }).then((result) => {
      // @ts-ignore
      if (result?.data){
        const langValue = value.toLowerCase();
        i18n.changeLanguage(langValue);
        moment.locale(langValue);
        localStorage.setItem('locale', langValue);
        window.location.reload();
      }
    });
  }

  return (
    <Menu
      id='menu-language'
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      sx={{
        '.MuiMenuItem-root': {
          minHeight: 30
        },
        '.MuiList-root': {
          width: 240,
        },
      }}
    >
      <div className='app-bar__topContainer'>
        <Typography variant='body1' sx={{fontWeight: 500, color: COLORS.BLACK_DARK}}>
          {t('settings__text__interfaceLanguage')}
        </Typography>
      </div>
      <Divider sx={{marginBottom: 0, marginTop: '1px'}}/>
      <div className='app-bar__menuContainer app-bar__menuContainer_language'>
        {languages?.map((item) => {
          const isCurrentLanguage = item.code === lang;
          return (
            <MenuItem sx={{padding: '8px 16px'}}  onClick={() => handleChangeLanguage(item.code)} key={item.code}>
              {isCurrentLanguage && <CheckIcon sx={{color: 'rgba(0, 0, 0, 0.54)'}}/>}
              <Typography variant='body2' sx={{marginLeft: isCurrentLanguage ? 4 : 7, color: COLORS.BLACK_DARK}}>
                {item.name}
              </Typography>
            </MenuItem>
          )
        })}
      </div>
    </Menu>
  )
}

export default LanguageMenu
