import React from "react";
import {useTranslation} from "react-i18next";

import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import CheckIcon from '@mui/icons-material/Check';

import {COLORS} from "../../../../../config/constants";
import {useUpdateUserInfoMutation, useUser} from "../../../../../api/auth";
import {useCurrencies} from "../../../../../api/currencies";

type Props = {
    anchorEl: HTMLElement | null
    handleCloseMenu(): void
}

const CurrencyMenu = ({ anchorEl, handleCloseMenu } : Props) => {

  const { t } = useTranslation()

  const [ updateUserInfo ] = useUpdateUserInfoMutation()

  const user = useUser();

  const currency = user.currency;

  const currenciesRate = useCurrencies();

  if (!currenciesRate.length){
    return null;
  }

  const selectedCurrency = currenciesRate.filter(item => item.code === currency);
  const otherCurrencies = currenciesRate.filter(item => item.code !== currency);
  const sortedCurrencies = [ ...selectedCurrency, ...otherCurrencies ];

  const handleChangeCurrency = async (code: string) => {
    await updateUserInfo({
      'currency': code,
    }).then((result) => {
      // @ts-ignore
      if (result?.data){
        handleCloseMenu();
      }
    });
  }

  return (
    <Menu
      id='menu-language'
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      sx={{
        '.MuiMenuItem-root': {
          minHeight: 30
        },
        '.MuiList-root': {
          width: '320px !important',
        },
      }}
    >
      <div className='app-bar__topContainer'>
        <Typography variant='body1' sx={{fontWeight: 500, color: COLORS.BLACK_DARK}}>
          {t('settings__text__currencies')}
        </Typography>
      </div>
      <Divider sx={{marginBottom: 0, marginTop: '1px'}}/>
      <div className='app-bar__menuContainer'>
        {sortedCurrencies.map((item, index) => {
          const isCurrentLanguage = item.code === currency;
          return (
            <MenuItem sx={{padding: '8px 16px'}} onClick={() => handleChangeCurrency(item.code)} key={index}>
              {isCurrentLanguage && <CheckIcon sx={{color: 'rgba(0, 0, 0, 0.54)'}}/>}
              <Typography variant='body2' sx={{marginLeft: isCurrentLanguage ? 4 : 7, color: COLORS.BLACK_DARK, fontWeight: 500}}>
                {item.code}&nbsp;
              </Typography>
              <Typography variant='body2' sx={{color: COLORS.BLACK_DARK}}>
                {item.name}
              </Typography>
            </MenuItem>
          )
        })}
      </div>
      <Divider sx={{marginBottom: 0, marginTop: 0}}/>
      {currenciesRate && <div className='app-bar__currenciesListContainer'>
        {currenciesRate.map((item, index) => (
          <MenuItem sx={{padding: '8px 16px'}} key={index}>
            <Typography variant='body2' sx={{marginLeft: 7, color: COLORS.BLACK_DARK}}>
                1 USD = {item.rate} {item.code}
            </Typography>
          </MenuItem>
        ))}
      </div>}
      {/*<Divider sx={{marginBottom: 0, marginTop: 0}}/>*/}
      {/*<div className='app-bar__bottomContainer'>*/}
      {/*  <Typography variant='overline'*/}
      {/*    className='app-bar__bottomButton'*/}
      {/*    onClick={handleCloseMenu}*/}
      {/*    sx={{*/}
      {/*      margin: '0 0 0 auto',*/}
      {/*      fontSize: '13px',*/}
      {/*      fontWeight: 500,*/}
      {/*      color: COLORS.BLACK_DARK,*/}
      {/*    }}>*/}
      {/*    {t('settings__text__allCurrencies')}*/}
      {/*  </Typography>*/}
      {/*</div>*/}
    </Menu>
  )
}

export default CurrencyMenu
