import React from "react";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";

export const getBreadcrumbs = (name: string) => {
  const { t } = useTranslation()

  return [
    <Link underline='hover' key='1' color='inherit' href='/' sx={{ display: 'flex', alignItems: 'center' }}>
      {t('menu__text__main')}
    </Link>,
    <Link
      underline='hover'
      key='2'
      color='inherit'
      href='/dictionaries'
    >
      {t('menu__text__dictionaries')}
    </Link>,
    <Typography key='3' color='text.primary'>
      {name}
    </Typography>,
  ]
}
