import React, {useEffect, useRef, useState} from "react";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useReactToPrint} from "react-to-print";

import Button from "@mui/material/Button";
import SendIcon from '@mui/icons-material/Send';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import TurnedInOutlinedIcon from '@mui/icons-material/TurnedInOutlined';

import { CalculationStatus } from "../../../calculations/components/calculation";
import {
  CALC_ID, useBaseCurrency, useGetOrderSummaryByIdQuery,
  useGetOrderSummaryInvoicesByIdQuery,
  useOrder, useSummary, useSummaryCompleted, useSummaryInvoices,
  useUpdateOrderMutation
} from "../../../../../api/calc";
import {useGetCalcIdFromRoute} from "../../../../../utils/hooks";
import AlertBottom from "../../../../common/alertBottom";
import {ROUTES} from "../../../../../config/constants";
import PrintOrder from "../../../../print/order";
import {numberWithSpaces} from "../../../../../utils/helpers";

import COLORS from "../../../../../styles/colors.scss";

import {getPluralForm} from "../../../../../utils/validation";

import './summary.scss';

const enum ACTIONS {
  PRINT = 'PRINT',
}

export const enum DISTRIBUTION_TYPE {
  PRICE = 'price',
  VOLUME = 'volume',
}

export const enum ORDERS_TYPE {
  WITH_VAT = 'with_vat',
  WITHOUT_VAT = 'without_vat',
}

const Summary = () => {

  const { t } = useTranslation()

  const componentRef = useRef();

  const [ action, setAction ] = React.useState<string>();

  const [ openAlert, setOpenAlert ] = React.useState(false);

  const orderId = useGetCalcIdFromRoute()

  const navigate = useNavigate();

  const { refetch } = useGetOrderSummaryInvoicesByIdQuery(orderId)
  const { refetch: summaryRefetch } = useGetOrderSummaryByIdQuery(orderId)

  const summaryInfo = useSummaryInvoices();

  const summaryCompleted = useSummaryCompleted()

  const summaryOrder = useSummary();

  const baseCurrency = useBaseCurrency();

  const [ distributionType, setDistributionType ] = useState<DISTRIBUTION_TYPE>(DISTRIBUTION_TYPE.PRICE);

  const [ ordersType, setOrdersType ] = useState<ORDERS_TYPE>(ORDERS_TYPE.WITH_VAT);

  useEffect(() => {
    refetch();
  }, [ summaryCompleted ])

  useEffect(() => {
    summaryRefetch();
  }, [ ])

  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => componentRef.current,
    documentTitle: `${t('computation__text__computation')} №${orderId}`
  });

  const order = useOrder()

  const [ updateOrder ] = useUpdateOrderMutation()

  if (!summaryOrder){
    return null;
  }

  const handleClick = async () => {
    await updateOrder({
      status: "done",
      id: orderId
    })
    setOpenAlert(true);
    localStorage.removeItem(CALC_ID);
    setTimeout(() => {
      navigate(`/${ROUTES.CABINET}/${ROUTES.CALCULATIONS}`, { replace: true });
    }, 1000);
  }

  const getPageMargins = () => `@page { margin: 32px 48px !important; }`

  // @ts-ignore
  const calculationStatusDone = order.status === CalculationStatus.DONE;

  const handleChangeDistributionType = (type: DISTRIBUTION_TYPE) => {
    setDistributionType(type);
  }

  const handleChangeOrdersType = (type: ORDERS_TYPE) => {
    setOrdersType(type);
  }

  const PRODUCTS_DECLENSIONS: PluralForms = [ t('declension__text__itemOne'), t('declension__text__itemSeveral'), t('declension__text__itemMany') ];

  const totalProducts = summaryInfo?.reduce((sum, invoice) => sum + (invoice.products?.length || 0), 0);

  return <>
    <div className='summary-calculator'>
      <Typography variant='h5' sx={{
        display: 'flex', alignItems: 'center', fontWeight: '700', padding: '30px 24px 4px'
      }}>
        <ContentPasteOutlinedIcon
          sx={{
            color: COLORS.LIGHT_TEXT_DISABLED,
            marginRight: 2,
            width: 32,
            height: 33,
          }}/> {t('calc__text__summary__finalCalculationOfCargoDelivery')} №{order.id}
      </Typography>
      <Divider sx={{ marginTop: '20px' }}/>
      <div className='summary-calculator__sorting'>
        <FormControl sx={{width: '332px', boxSizing: 'border-box'}}>
          <InputLabel id='distribution-group-label'>{t('calc__text__summary__distribution')}</InputLabel>
          <Select
            labelId='distribution-group-label'
            id='distribution-group-label'
            // @ts-ignore
            value={distributionType}
            label={t('calc__text__summary__distribution')}
            // @ts-ignore
            onChange={({target: {value: type}}) => handleChangeDistributionType(type)}
          >
            <MenuItem key={DISTRIBUTION_TYPE.PRICE} value={DISTRIBUTION_TYPE.PRICE}>
              {t('calc__text__summary__distributionByPrice')}
            </MenuItem>
            <MenuItem key={DISTRIBUTION_TYPE.VOLUME} value={DISTRIBUTION_TYPE.VOLUME}>
              {t('calc__text__summary__distributionByVolume')}
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{width: '332px', boxSizing: 'border-box'}}>
          <InputLabel id='distribution-group-label'>{t('calc__text__summary__orders')}</InputLabel>
          <Select
            labelId='orders-group-label'
            id='orders-group-label'
            // @ts-ignore
            value={ordersType}
            label={t('calc__text__summary__distribution')}
            // @ts-ignore
            onChange={({target: {value: type}}) => handleChangeOrdersType(type)}
          >
            <MenuItem key={ORDERS_TYPE.WITH_VAT} value={ORDERS_TYPE.WITH_VAT}>
              {t('calc__text__summary__ordersWithVAT')}
            </MenuItem>
            <MenuItem key={ORDERS_TYPE.WITHOUT_VAT} value={ORDERS_TYPE.WITHOUT_VAT}>
              {t('calc__text__summary__ordersWithoutVAT')}
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      {summaryInfo?.map((invoice, index) => (
        <div key={index} className='summary-calculator__info'>
          <Accordion sx={{
            marginBottom: 0,
            boxShadow: 'none',
            '&.Mui-expanded:last-of-type': {
              marginBottom: 0
            }
          }} defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
              sx={{
                padding: '20px 24px 22px',
                '> div': {margin: '6px 0 !important'},
                '&.Mui-expanded': {
                  borderBottom: 'none'
                },
                '.Mui-expanded.MuiAccordionSummary-content': {
                  margin: 0,
                },
                '.MuiAccordionSummary-content': {
                  margin: 0,
                },
                alignItems: 'start'
              }}
            >
              <div className='summary-calculator__title'>
                <div className='summary-calculator__title-left'>
                  <TurnedInOutlinedIcon sx={{color: COLORS.GREEN, marginRight: 2, marginTop: 1 }}/>
                  <div>
                    <Typography variant='h6'>
                      {t('common__text__invoice')} №{invoice.invoice_num + 1}
                    </Typography>
                    <Typography variant='caption' sx={{ lineHeight: 1.3, display: 'block' }}>
                      {`${invoice.products.length} ${getPluralForm(invoice.products.length, PRODUCTS_DECLENSIONS)}`}
                    </Typography>
                  </div>
                </div>
                <div className='summary-calculator__title-right'>
                  <Typography variant='h6' sx={{ marginTop: '2px' }}>
                    {numberWithSpaces(invoice?.price_total)} {baseCurrency}
                  </Typography>
                  <Typography
                    variant='caption'
                    sx={{
                      display: 'block',
                      textAlign: 'right',
                      textTransform: 'lowercase',
                      lineHeight: 1.3
                    }}>
                    {t('calc__text__summary__ordersWithVAT')}
                  </Typography>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails
              sx={{borderTop: `1px solid ${COLORS.SLIGHTLY_GRAY}`,
                marginLeft: 3,
                marginRight: 3,
                padding: 0,
              }}>
              <table className='summary-calculator__table'>
                <thead>
                  <tr>
                    <th>
                      <Typography variant='body2'>
                      №
                      </Typography>
                    </th>
                    <th>
                      <Typography variant='body2'>
                        {t('calc__text__packaging__nameItem')}
                      </Typography>
                    </th>
                    <th>
                      <Typography variant='body2'>
                        {t('calc__text__summary__count')}
                      </Typography>
                    </th>
                    <th>
                      <Typography variant='body2'>
                        {t('common__text__price')}, USD
                      </Typography>
                    </th>
                    <th>
                      <Typography variant='body2'>
                        {t('common__text__all')}, USD
                      </Typography>
                    </th>
                    <th>
                      <Typography variant='body2'>
                        {t('common__text__vat')}, %
                      </Typography>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {invoice.products.map((product, index) => (
                    <tr>
                      <td>
                        <Typography variant='body2' sx={{color: COLORS.BLACK_DARK}}>
                          {index + 1}
                        </Typography>
                      </td>
                      <td>
                        <Typography variant='body2' sx={{color: COLORS.BLACK_DARK}}>
                          {product.name}
                        </Typography>
                      </td>
                      <td>
                        <Typography variant='body2' sx={{color: COLORS.BLACK_DARK}}>
                            -
                        </Typography>
                      </td>
                      <td>
                        <Typography variant='body2' sx={{color: COLORS.BLACK_DARK}}>
                          {product.price_unit}
                        </Typography>
                      </td>
                      <td>
                        <Typography variant='body2' sx={{color: COLORS.BLACK_DARK}}>
                          {product.price_total}
                        </Typography>
                      </td>
                      <td>
                        <Typography variant='body2' sx={{color: COLORS.BLACK_DARK}}>
                            -
                        </Typography>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
      <div className={cx('summary-calculator__buttons', {
        'summary-calculator__buttons-one': calculationStatusDone,
      })}>
        <div className='summary-calculator__title'>
          <div>
            <Typography variant='h6'>
              {t('common__text__inTotalCaps')}
            </Typography>
            {totalProducts && <Typography variant='caption' sx={{ lineHeight: 1, display: 'block' }}>
              {`${totalProducts} ${getPluralForm(totalProducts, PRODUCTS_DECLENSIONS)}`}
            </Typography>}
          </div>
          <div>
            <Typography variant='h6' sx={{ textAlign: 'right' }}>
              {numberWithSpaces(summaryOrder?.total_amount)} {baseCurrency}
            </Typography>
            <Typography variant='caption' sx={{ lineHeight: 1, display: 'block' }}>
              ≈ 12 334 500 рублей, с учетом НДС
            </Typography>
          </div>
        </div>
        {!calculationStatusDone && (
          <Button
            sx={{maxHeight: '42px', marginTop: 1}}
            color='secondary'
            startIcon={<SendIcon/>}
            variant='contained'
            fullWidth
            onClick={handleClick}
          >
            {t('calc__text__summary__placeAnOrderForTheDeliveryOfCargo')}
          </Button>
        )}
      </div>
      <div className='summary-calculator__actions'>
        <FormControl
          sx={{width: '344px'}}>
          <InputLabel id='action-select'>{t('actions__text__selectAction')}</InputLabel>
          <Select
            labelId='action-select'
            id='action-select'
            value={action}
            label={t('actions__text__selectAction')}
            onChange={({target: {value}}) => setAction(value)}
          >
            <MenuItem
              value={ACTIONS.PRINT}
              onClick={handlePrint}
            >{t('actions__text__print')}</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
    <AlertBottom
      open={openAlert}
      onClose={() => setOpenAlert(false)}
      message={t('calc__text__summary__calculationSaved')}
    />
    <div className='summary-calculator__print-version'>
      <PrintOrder ref={componentRef}/>
      <style>{getPageMargins()}</style>
    </div>
  </>
}

export default Summary
