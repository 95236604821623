import React, { useEffect } from "react";
import {useTranslation} from "react-i18next";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import TablePaginationActions from "./components/TablePaginationActions";
import EnhancedTableHead from "./components/EnhancedTableHead";

import {RESULTS_COUNT} from "../../../../../../../../../../../../../utils/constants";
import {HeaderCell} from "../../../../../../../../../../../dictionaries/components/table/components/EnhancedTableHead";

const enum SORTING_OPTIONS {
  CODE_DESC = 'code',
  CODE_ASC = '-code',
  NAME_DESC = 'name',
  NAME_ASC = '-name',
}

type Preferences = {
    id: string
    width: number
}

type Props = {
    rows: any[],
    preferences: Preferences[]
    count?: number
    primaryField?: string
    hasEmptySelection?: boolean
    headCells: HeaderCell[]
    handleSendRequest?(data: DictionaryParamsInput): void
    actionsAfterTable?(value: string[]): void
    chooseItem?(id: string[]): void
}

const ProductsTable = ({
  rows,
  preferences,
  handleSendRequest,
  count,
  primaryField,
  chooseItem,
  actionsAfterTable,
  hasEmptySelection,
  headCells
}: Props) => {

  const { t } = useTranslation()

  const [ page, setPage ] = React.useState(0);
  const [ rowsPerPage, setRowsPerPage ] = React.useState(RESULTS_COUNT.COUNT_50);
  const [ orderBy, setOrderBy ] = React.useState<string>(SORTING_OPTIONS.CODE_DESC);
  const [ selected, setSelected ] = React.useState<string[]>([]);

  useEffect(() => {
    if (hasEmptySelection){
      setSelected([])
    }
  }, [ hasEmptySelection ])


  if (!count || !primaryField) {
    return null;
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
        page > 0 ? Math.max(0, rowsPerPage - rows.length) : 0;

  const sendRequest = (data: DictionaryParamsInput) => {
    handleSendRequest && handleSendRequest({
      page: page + 1,
      limit: rowsPerPage,
      ordering: orderBy,
      ...data
    })
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
    sendRequest({
      page: newPage + 1
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const limit = parseInt(event.target.value, 10);
    setRowsPerPage(limit);
    setPage(0);
    sendRequest({
      limit,
      page: 1,
    });
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    setOrderBy(property);
    sendRequest({
      ordering: property
    })
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n[primaryField]);
      setSelected(newSelected);
      chooseItem && chooseItem(newSelected);
      return;
    }
    setSelected([]);

  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const hasValueInArray = isSelected(id);
    const newSelected: string[] = hasValueInArray ?
      selected.filter((item) => item !== id) : [ ...selected, id ];
    setSelected(newSelected);
    chooseItem && chooseItem(newSelected);
  };

  const isSelected = (id: string) => selected.includes(id);

  const getPaddingCell = (index: number, length: number) => {
    switch (index) {
      case 0:
        return '16px';
      case length - 1:
        return '16px 8px 16px 0';
      default:
        return '16px 16px 16px 0';
    }
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ padding: '24px 24px 16px' }}>
        <Table sx={{ '.MuiTableSortLabel-root svg': {
          marginRight: 0,
          marginLeft: 0,
          position: 'absolute',
          right: '-8px',
          bottom: 0,
        } }}>
          <EnhancedTableHead
            numSelected={selected.length}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
            count={count}
            headCells={headCells}
          />
          <TableBody>
            {rows.map((row) => {
              const isItemSelected = isSelected(row[primaryField]);
              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row[primaryField])}
                  role='checkbox'
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  sx={{ cursor: 'pointer' }}
                >
                  {preferences.map((preference, index) => (
                    <TableCell
                      component='th'
                      scope='row'
                      style={{ width: preference.width }}
                      key={preference.id}
                      sx={{
                        padding: getPaddingCell(index, preferences.length),
                        textAlign: index === 1 || index === 0 ? 'left' : 'right',
                      }}
                    >
                      {row[preference.id]}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[ RESULTS_COUNT.COUNT_50, RESULTS_COUNT.COUNT_100, RESULTS_COUNT.COUNT_500 ]}
                colSpan={preferences.length}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={t('common__text__rowsCount')}
                labelDisplayedRows={({ from, to, count }) => { return `${from}–${to} ${t('common__text__of')} ${count !== -1 ? count : `${t('common__text__moreThan')} ${to}`}`; }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {actionsAfterTable && actionsAfterTable(selected)}
    </>
  )
};

export default ProductsTable
