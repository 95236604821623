import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import sortBy from "lodash/sortBy";
import moment from "moment";

import AddIcon from '@mui/icons-material/Add';
import TableViewIcon from '@mui/icons-material/TableView';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import {
  useDeclarationsByOrderIdQuery,
  useDeclarationSummaryByOrderIdQuery,
  useGetAdditionalServicesByOrderIdQuery,
  useGetOrderSummaryInvoicesByIdQuery,
  useInvoicesByOrderIdQuery,
  useLazyProductsSearchByInvoiceIdQuery,
  useSummaryByOrderIdQuery,
  useProductsByInvoiceIdQuery,
  useRemoveProductMutation,
  useSummary,
  useGetOrder,
  useProductsList,
} from "../../../../../../../../../../../api/calc";

import ModalBig from "../../../../../../../../../../common/modalBig";
import ModalAction from "../../../../../../../../../../common/modalAction";
import Completed from "../../../../../../../../../../common/completed";
import {useGetCalcIdFromRoute} from "../../../../../../../../../../../utils/hooks";
import {formatHSCode, numberWithSpaces} from "../../../../../../../../../../../utils/helpers";
import {COLORS} from "../../../../../../../../../../../config/constants";
import {useLazyGetTnvedQuery} from "../../../../../../../../../../../api/tnved";
import ModalCode from "../../../../../../../customs/components/modal_code";

import ProductsTable from "../products_table";
import AddForm from "./components/add_form";

import './products.scss';

type Props = {
    openByDefault?: boolean
    invoice?: InvoiceParamsOutput
    productCount: number
}

const Products = ({openByDefault, invoice, productCount} : Props) => {

  const { t } = useTranslation()

  const { data: products } = useProductsByInvoiceIdQuery(invoice?.id || 0, {skip: !invoice?.id})

  const [ showAddForm, setShowAddFrom ] = useState<boolean | undefined>(false);

  const [ openEditModal, setOpenEditModal ] = useState(false);

  const [ openProductsModal, setOpenProductsModal ] = useState(false);

  const [ currentProductId, setCurrentProductId ] = useState<number>();

  const [ shouldOpenRemoveModal, setShouldOpenRemoveModal ] = useState(false)

  const [ shouldRefetchCustoms, setShouldRefetchCustoms ] = useState(true);

  const [ openModalCode, setOpenModalCode ] = useState(false);

  const [ tnvedInfo, setTnvedInfo ] = useState<TnvedInfo>();

  const [ tnvedRequest ] = useLazyGetTnvedQuery()

  const [ productsListRequest ] = useLazyProductsSearchByInvoiceIdQuery()

  const handleClickCodeLink = (code: string) => {
    tnvedRequest(code)?.then((response) => {
      setTnvedInfo(response?.data);
      setOpenModalCode(true);
    });
  }

  const productsListParams = useProductsList()?.params;

  const [ removeProduct ] = useRemoveProductMutation()

  const orderId = useGetCalcIdFromRoute()

  const summaryOrder = useSummary();

  const order = useGetOrder();

  const isPackagingCompleted = Boolean(summaryOrder?.delivery_type);

  const {data: invoices, isSuccess} = useInvoicesByOrderIdQuery(orderId, {skip: !orderId})
  const {refetch: refetchInvoices} = useInvoicesByOrderIdQuery(orderId, {skip: !orderId})
  const {refetch: refetchDeclaration} = useDeclarationsByOrderIdQuery(orderId, {skip: !shouldRefetchCustoms || !orderId})
  const {refetch: refetchSummaryDeclaration} = useDeclarationSummaryByOrderIdQuery(orderId, {skip: !shouldRefetchCustoms || !orderId})
  const {refetch: refetchSummaryPackaging} = useSummaryByOrderIdQuery(orderId, {skip: !shouldRefetchCustoms || !orderId})
  const {refetch: refetchOrderSummary} = useGetOrderSummaryInvoicesByIdQuery(orderId, {skip: !orderId})
  const {refetch: refetchAdditionalServices} = useGetAdditionalServicesByOrderIdQuery(orderId, {skip: !orderId})
  const {refetch: refetchProductsByInvoiceId} = useProductsByInvoiceIdQuery(invoice?.id || 0, {skip: !invoice?.id})

  useEffect(() => {
    if (!openByDefault) {
      setShowAddFrom(!invoices);
    }
  }, [ isSuccess ])

  useEffect(() => {
    setShowAddFrom(!products?.length && Boolean(invoice?.id));
  }, [ invoice, products ])

  const getCompletedInfoProduct = (product: ProductParamsOutput) =>
    [
      {
        label: t('common__text__codeOfHS'),
        value: <div>
          <a
            className='products__item__link'
            onClick={() => handleClickCodeLink(product.code)}>{formatHSCode(product.code)}</a>
          <ModalCode
            isOpen={openModalCode}
            onClose={() => setOpenModalCode(false)}
            code={product.code}
            tnvedInfo={tnvedInfo}
          />
        </div>
      },
      {
        label: t('common__text__totalQuantity'),
        value: `${product.pieces} ${t('units__text__items')}`
      },
      {
        label: t('common__text__pricePerPiece'),
        value: `${numberWithSpaces(product.unit_price)} USD`
      },
      {
        label: t('common__text__totalCost'),
        value: `${numberWithSpaces(product.price_total)} USD`
      },
      ...(product.places_total
        ? [
          {
            label: t('common__text__numberOfPlaces'),
            value: `${product.places_total} ${t('units__text__items')}`
          },
        ]
        : []),
      ...(product.pieces_in_cartoon
        ? [
          {
            label: t('common__text__quantityPerPackage'),
            value: `${product.pieces_in_cartoon} ${t('units__text__items')}`
          },
        ]
        : []),
      ...(product.length
        ? [
          {
            label: t("common__text__lengthWidthHeight"),
            value: `${product.length} x ${product.width} x ${product.height} ${t("units__text__centimetre")}`,
          },
        ]
        : []),
      ...(product.volume_cartoon
        ? [
          {
            label: t('common__text__packageVolume'),
            value: <>
              {`${product.volume_cartoon} ${t('units__text__meter')}`}
              <sup>3</sup>
            </>
          },
        ]
        : []),
      ...(product.net_weight_cartoon
        ? [
          {
            label: t('common__text__packageWeight'),
            value: <>
              {`${product.net_weight_cartoon} ${t('units__text__kg')}`} / {`${product.gross_weight_cartoon} ${t('units__text__kg')}`}
            </>
          },
        ]
        : []),
      ...(product.volume_total
        ? [
          {
            label: t('common__text__totalVolume'),
            value: <>
              {`${product.volume_total} ${t('units__text__meter')}`}
              <sup>3</sup>
            </>
          },
        ]
        : []),
      ...(product.net_weight_total
        ? [
          {
            label: t('common__text__netGrossWeight'),
            value: <>
              {`${product.net_weight_total} ${t('units__text__kg')}`} / {`${product.gross_weight_total} ${t('units__text__kg')}`}
            </>
          },
        ]
        : []),
      ...(product.volume_manual
        ? [
          {
            label: t("common__text__totalVolume"),
            value: <>
              {`${product.volume_manual} ${t('units__text__meter')}`}
              <sup>3</sup>
            </>,
          },
        ]
        : []),
      ...(product.net_weight_manual
        ? [
          {
            label: t("common__text__totalNetWeight"),
            value: <>
              {`${product.net_weight_manual} ${t('units__text__kg')}`}
              <sup>3</sup>
            </>,
          },
        ]
        : []),
      ...(product.gross_weight_manual
        ? [
          {
            label: t("common__text__totalGrossWeight"),
            value: <>
              {`${product.gross_weight_manual} ${t('units__text__kg')}`}
              <sup>3</sup>
            </>,
          },
        ]
        : []),
      ...(product.pallete_cnt
        ? [
          {
            label: t("calc__text__packaging__numberOfPalletes"),
            value: product.pallete_cnt,
          },
        ]
        : []),
      ...(product.pallete_height
        ? [
          {
            label: t("calc__text__packaging__heightOfThePallet"),
            value: product.pallete_height,
          },
        ]
        : []),
      ...(product.pallete_type
        ? [
          {
            label: t("calc__text__packaging__palletType"),
            value: product.pallete_type.toUpperCase(),
          },
        ]
        : []),
      ...(product.pallete_weight
        ? [
          {
            label: t("calc__text__packaging__weightOfThePallet"),
            value: product.pallete_weight,
          },
        ]
        : []),
    ]

  const onEdit = (id: number) => {
    setCurrentProductId(id);
    setOpenEditModal(true)
  }

  const removeItem = (productId?: number | string) => {
    if (productId && invoice?.id) {
      removeProduct({
        invoiceId: invoice?.id,
        productId: Number(productId)
      }).then(async () => {
        setShouldOpenRemoveModal(false)
        refetchProductsByInvoiceId()
        refetchInvoices()
        setShouldRefetchCustoms(true);

        if (isPackagingCompleted) {
          refetchDeclaration()
          refetchSummaryDeclaration()
          refetchSummaryPackaging()
          refetchOrderSummary()
          refetchAdditionalServices()
        } else {
          refetchSummaryDeclaration()
        }

        if (openProductsModal) {
          productsListRequest(productsListParams);
        }
      })

    }
  }

  const onRemove = (id: number) => {
    setCurrentProductId(id)
    setShouldOpenRemoveModal(true)
  }

  const handleCloseAddForm = () => {
    setShowAddFrom(false)
    refetchProductsByInvoiceId()
    setOpenEditModal(false)

    if (openProductsModal) {
      productsListRequest(productsListParams);
    }
  }

  const handleSuccessAddedProduct = () => {
    refetchInvoices()
    refetchProductsByInvoiceId();
    setShouldRefetchCustoms(true);
    setShowAddFrom(false);

    if (isPackagingCompleted) {
      refetchDeclaration()
      refetchSummaryDeclaration()
      refetchSummaryPackaging()
      refetchOrderSummary()
    }
  }

  const sortedProducts = sortBy(products, 'id');

  const findProductNameById = (productId?: number) => {

    if (!products || !productId) {
      return;
    }

    // @ts-ignore
    return products?.find((product) => product.id === Number(productId)).name;
  }

  const handleRemove = () => {
    setOpenEditModal(false);
    setShouldOpenRemoveModal(true);
  }

  const getCompletedCaption = (product: ProductParamsOutput, index: number) =>
    <span className='products__item-name'>
      {index + 1}. {product.name}
    </span>

  const shouldShowAddButton = Boolean(invoice?.id) && !showAddForm;

  // @ts-ignore
  const titleProductTable = `${order?.data?.order_name}. ${t('common__text__invoice')} № ${invoice?.invoice_num} ${t('common__text__from')} ${moment(invoice?.invoice_date).format('Do MMMM YYYY')}`;

  return (
    <div className='products'>
      {(productCount > 0 || showAddForm) && (
        <Typography variant='h6' sx={{marginTop: 3, marginBottom: 3}}>
          {t('common__text__products')}
          {productCount > 0 && <span className='invoice__product-count'>{productCount}</span>}
        </Typography>
      )}
      {sortedProducts?.map((product, index) => (
        <div className='products__item-completed' key={product.id}>
          <Completed
            isFullRow
            info={getCompletedInfoProduct(product)}
            onEdit={() => onEdit(product.id)}
            onRemove={() => onRemove(product.id)}
            caption={getCompletedCaption(product, index)}
            hasCollapse
            total={product.price_total}
          />
        </div>
      ))}
      {showAddForm && (<div className='products__add-form'>
        <AddForm
          handleRemove={() => setShowAddFrom(false)}
          invoice={invoice}
          handleSuccess={handleSuccessAddedProduct}
        />
      </div>)}
      {invoice?.id && <IconButton
        sx={{
          padding: 0,
          '&:hover': {background: 'transparent'},
          'svg': {fill: COLORS.BLUE}
        }}
        onClick={() => setOpenProductsModal(true)}
      >
        <TableViewIcon sx={{marginRight: 1, color: COLORS.BLUE}}/>
        <Typography variant='body1' sx={{color: COLORS.BLUE}}>
          {t('calc__text__packaging__tableOfProducts')}
        </Typography>
      </IconButton>}
      {shouldShowAddButton && (
        <div className='products__add-button'>
          <Button
            variant='outlined'
            fullWidth
            onClick={() => setShowAddFrom(true)}
          >
            <AddIcon sx={{marginRight: 1}}/>
            {t('actions__text__addItem')}
          </Button>
        </div>
      )}
      {openEditModal && <ModalBig
        isOpen
        onClose={() => setOpenEditModal(false)}
        title={t('actions__text__editItem')}
      >
        <AddForm
          invoice={invoice}
          handleSuccess={handleCloseAddForm}
          id={currentProductId}
          handleRemove={handleRemove}
          insideModal
        />
      </ModalBig>}
      {openProductsModal && invoice?.id && <ModalBig
        isOpen
        onClose={() => setOpenProductsModal(false)}
        title={titleProductTable}
        width={1344}
        noPadding
      >
        <ProductsTable
          invoiceId={invoice?.id}
          onEdit={onEdit}
          onRemove={onRemove}
          title={titleProductTable}
        />
      </ModalBig>}
      {shouldOpenRemoveModal && <ModalAction
        isOpen={shouldOpenRemoveModal}
        onContinue={removeItem}
        index={currentProductId}
        onClose={() => setShouldOpenRemoveModal(false)}
        title={t('common__text__deleteItem')}
        description={<>
          {t('actions__message__removeConfirm')}&nbsp;
          <span className='text_bold'>{`«${findProductNameById(currentProductId)}»?`}</span>
        </>}
        buttonYesText={t('actions__text__yesDelete')}
      />}
    </div>
  )
}

export default Products
