import React from "react";

import Typography from "@mui/material/Typography";

import {useTranslation} from "react-i18next";

import ModalBig from "../../../../../../common/modalBig";

import ModalTable from '../modal_table';
import './modal_code.scss';
import {getTnvedVAT} from "../../utils";
import {formatHSCode} from "../../../../../../../utils/helpers";
import Divider from "@mui/material/Divider";
import HomeIcon from "@mui/icons-material/Home";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
    isOpen: boolean
    onClose(): void
    insideModal?: boolean
    code: string
    tnvedInfo?: TnvedInfo
}

const ModalCode = ( {isOpen, onClose, insideModal, code, tnvedInfo}: Props) => {

  const { t } = useTranslation()

  if (!tnvedInfo) {
    return null;
  }
  const tnvedInfoVat = getTnvedVAT(tnvedInfo);
  const name = tnvedInfo.GoodInfo.Name;

  return <ModalBig
    isOpen={isOpen}
    onClose={onClose}
    title={`${t('common__text__codeOfHS')} ${formatHSCode(code)}`  }
    insideModal={insideModal}
    noPadding
  >
    <>
      <div className='code-table__section-header'>
        <Typography variant='h6'>
          {t('calc__text__customs__productCodeInformation')} {formatHSCode(code)}
        </Typography>
        {name && (
          <Typography variant='body1' sx={{marginTop: 1, marginBottom: 1}}>
            {name}
          </Typography>
        )}
      </div>
      <Divider sx={{marginTop: 0}}/>
      {tnvedInfo.Hierarchy && tnvedInfoVat &&
      <ModalTable tnvedInfoHierarchy={tnvedInfo.Hierarchy} tnvedInfoVat={tnvedInfoVat}/>}
      <Divider sx={{marginTop: 0}}/>
      <div className='code-table__section-bottom'>
        <Typography variant='body2'>
          {t('calc__text__customs__dataSource')}: <a href='' className='code-table__link'>
            {`https://www.alta.ru/tnved/code/${code}/`}
          </a>
        </Typography>
        <Button
          variant='contained'
          startIcon={<CloseIcon />}
          onClick={onClose}
          fullWidth
          sx={{ marginTop: 3, marginBottom: 2 }}
        >
          {t('actions__text__closeModal')}
        </Button>
      </div>
    </>
  </ModalBig>
}

export default ModalCode
