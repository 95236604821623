import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import {
  useLazyGetTransportCompanyQuery,
  useTransportCompanies,
  useUpdateTransportCompanies
} from "../../../../../api/dictionary";
import SearchField from "../../components/search_field";
import Header from './../../components/header';
import Preloader from "../../../../common/preloader";

import {INITIAL_DATA_REQUEST} from "../../constants";

import TransportCompaniesActions from "./actions";
import TransportCompanyTable from "./table";

import './transport_companies.scss';

const TransportCompanies = () => {

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [ currentTransportCompany, setCurrentTransportCompany ] = useState<string[]>([])

  const [ showModalRemoveByDefault, setShowModalRemoveByDefault ] = useState(false)
  const [ showModalAddByDefault, setShowModalAddByDefault ] = useState(false)

  const [ hasLoadedFirstRequest, setHasLoadedFirstRequest ] = useState(false)

  const [ transportCompaniesRequest, { isLoading: isLoadingTransportCompanies } ] = useLazyGetTransportCompanyQuery()

  const transportCompaniesParams = useTransportCompanies()?.params;

  const transportCompanyRequestUpdate = (params?: DictionaryParamsInput) =>
    transportCompaniesRequest({...transportCompaniesParams, ...params});

  useEffect(() => {
    transportCompaniesRequest(INITIAL_DATA_REQUEST).then(() =>
      setHasLoadedFirstRequest(true)
    )
  }, [])


  useEffect(() => {
    return () => {
      // @ts-ignore
      dispatch(useUpdateTransportCompanies());
    };
  }, []);

  const transportCompaniesRequestSearch = (query: string) => transportCompaniesRequest({
    ...transportCompaniesParams,
    search: query,
  })

  const handleEdit = () => {
    setShowModalAddByDefault(true);
  }

  const handleOpenRemoveModal = () => setShowModalRemoveByDefault(true)

  const handleCloseModal = () => {
    setCurrentTransportCompany([]);
    setShowModalRemoveByDefault(false);
    setShowModalAddByDefault(false);
  }

  const isLoadingStatus = isLoadingTransportCompanies && !hasLoadedFirstRequest;

  return (
    <>
      <div>
        <Header menuText={t('menu__text__transport_companies')}/>
        {isLoadingStatus ? <Preloader/> : <div className='transport-company-container'>
          <Card>
            <CardContent sx={{ padding: 0, '&:last-child' : { paddingBottom: 0 } }}>
              <div className='transport-company-container__search-container'>
                <SearchField onSearch={transportCompaniesRequestSearch}  />
              </div>
              <TransportCompanyTable
                handleEdit={handleEdit}
                handleRemove={handleOpenRemoveModal}
                handleTransportCompaniesRequest={transportCompanyRequestUpdate}
                changeTransportCompany={setCurrentTransportCompany}
                currentTransportCompanies={currentTransportCompany}
              />
            </CardContent>
          </Card>
        </div>}
      </div>
      {!isLoadingStatus && (
        <div>
          <TransportCompaniesActions
            transportCompaniesRequestUpdate={transportCompanyRequestUpdate}
            isOpenRemoveByDefault={showModalRemoveByDefault}
            isOpenAddByDefault={showModalAddByDefault}
            currentTransportCompanies={currentTransportCompany}
            onCloseModal={handleCloseModal}
          />
        </div>
      )}
    </>
  )
};

export default TransportCompanies
