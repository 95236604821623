import React, {ReactNode } from 'react'

import Typography from "@mui/material/Typography";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import COLORS from 'styles/colors.scss'
import cx from "classnames";

import './template.scss'

type InfoParams = {
    label: string
    value: ReactNode
}

type InfoRow = InfoParams[]

type TemplateParams = {
    text: string
    isCompleted?: boolean
    noLimeColor?: boolean
    inProgress?: boolean
    isExpanded?: boolean
    isDisabled?: boolean
    onClick(): void
    info: InfoRow[]
}

const Template = (props: TemplateParams) => {

  const shouldShowProgressIcon = (!props.isDisabled || props.inProgress) && !props.isCompleted;

  const infoData = props.info;

  return (
    <div className={cx('right-panel-item', {
      'right-panel-item_isCompleted': props.isCompleted,
      'right-panel-item_noLimeColor': props.noLimeColor,
    })}>
      <Accordion
        sx={{
          boxShadow: 0,
          marginBottom: 0,
          backgroundColor: props.isCompleted && !props.noLimeColor ? COLORS.LIME : COLORS.WHITE,
          '&.Mui-expanded': {
            '&:last-of-type': {
              marginBottom: '0'
            }
          }
        }}
        expanded={props.isExpanded}
        disabled={props.isDisabled}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='rightPanel'
          id='rightPanel'
          onClick={props.onClick}
          sx={{
            '.MuiAccordionSummary-content': {
              margin: '16px 0'
            },
            '.Mui-expanded.MuiAccordionSummary-content': {
              margin: '16px 0 8px'
            },
            '&.Mui-expanded': {
              borderBottom: 'none',
            },
            '.MuiAccordionSummary-expandIconWrapper': {
              svg: {
                width: '32px',
                height: '32px',
              }
            }
          }}
        >
          <Typography variant='subtitle1' className={cx('right-panel-item__text', {
            'right-panel-item__text_noData': props.isDisabled && !props.isCompleted,
          })}>
            {props.isCompleted && <CheckCircleIcon sx={{ color: COLORS.GREEN, marginRight: '12px', width: '24px', height: '24px'}} /> }
            {shouldShowProgressIcon && <CheckCircleOutlineIcon sx={{ color: COLORS.SLIGHTLY_GRAY, marginRight: '12px', width: '24px', height: '24px' }} />}
            {props.text}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{
          padding: '0 16px'
        }}>
          {infoData[0]?.length ?
            infoData.map((infoDataItem, i) => (
              <ul className='right-panel-item__details' key={i}>
                {infoDataItem.map((item, index) => (
                  <li className='right-panel-item__details-item' key={index}>
                    <Typography
                      variant='caption'
                      className='right-panel-item__label'
                      sx={{
                        background: props.isCompleted && !props.noLimeColor ? COLORS.LIME : COLORS.WHITE,
                        position: 'relative',
                        'paddingRight': '4px',
                      }}
                    >
                      {item.label}
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        "color": COLORS.BLACK,
                        "background": props.isCompleted && !props.noLimeColor ? COLORS.LIME : COLORS.WHITE,
                        "position": 'relative',
                        "maxWidth": '50%',
                        'paddingLeft': '4px',
                        "fontSize": '12px'
                      }}
                      className='right-panel-item__value'
                    >
                      {item.value}
                    </Typography>
                  </li>
                ))}
              </ul>
            )) : ''}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default Template
