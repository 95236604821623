import React from "react";
import { TFunction } from "i18next";

export const getColumnName = (columnId: string, t: TFunction) => {

  switch (columnId) {
    case 'id':
      return <span>№&nbsp;&nbsp;</span>;
    case 'name':
      return t('calc__text__packaging__nameItem');
    case 'pieces':
      return `${t('calc__text__packaging__tableOfProductsTotalCount')}, \n${t('units__text__items')}`;
    case 'places_manual':
      return `${t('calc__text__packaging__tableOfProductsPackagingCount')}, \n${t('units__text__items')}`;
    case 'pieces_in_cartoon':
      return `${t('calc__text__packaging__tableOfProductsQuantityPerPackage')}, \n${t('units__text__items')}`;
    case 'unit_price':
      return `${t('calc__text__packaging__tableOfProductsPricePerPiece')}, USD`;
    case 'price':
      return `${t('calc__text__packaging__tableOfProductsTotal')}, USD`;
    case 'volume_cartoon':
      return <span className='product-table-th'>{`${t('calc__text__packaging__tableOfProductsPackingVolume')}, ${t('units__text__meter')}`}<sup>3</sup></span>;
    case 'volume_manual':
      return <span className='product-table-th'>{`${t('common__text__totalVolume')}, ${t('units__text__meter')}`}<sup>3</sup></span>;
    case 'net_weight_manual':
      return <span className='product-table-th'>{`${t('common__text__totalNetWeight')}, ${t('units__text__kg')}`}</span>;
    case 'gross_weight_manual':
      return <span className='product-table-th'>{`${t('common__text__totalGrossWeight')}, ${t('units__text__kg')}`}</span>;
    case 'volume_total':
      return <span className='product-table-th'>{`${t('calc__text__packaging__tableOfProductsTotalPackageVolume')}, ${t('units__text__meter')}`}<sup>3</sup></span>;
    case 'net_weight_cartoon':
      return `${t('calc__text__packaging__tableOfProductsWeightPackagingNet')}, \n${t('units__text__kg')}`;
    case 'gross_weight_cartoon':
      return `${t('calc__text__packaging__tableOfProductsWeightPackagingGross')}, \n${t('units__text__kg')}`;
    case 'net_weight_total':
      return `${t('calc__text__packaging__tableOfProductsTotalPackageWeightNet')}, \n${t('units__text__kg')}`;
    case 'gross_weight_total':
      return `${t('calc__text__packaging__tableOfProductsTotalPackageWeightGross')}, \n${t('units__text__kg')}`;
    case 'pallete_cnt':
      return t('calc__text__packaging__numberOfPalletes');
    case 'pallete_type':
      return <span className='product-table-th'>{`${t('calc__text__packaging__palletType')}`}&nbsp;&nbsp;&nbsp;</span>;
    default:
      return '';
  }
}

export const getSettingsColumnName = (columnId: string, t: TFunction) => {

  switch (columnId) {
    case 'pieces':
      return t('common__text__totalQuantity');
    case 'places_manual':
      return t('common__text__numberOfPlaces');
    case 'pieces_in_cartoon':
      return t('common__text__quantityPerPackage');
    case 'unit_price':
      return t('common__text__pricePerPiece');
    case 'price':
      return t('common__text__inTotal');
    case 'volume_cartoon':
      return t('common__text__packageVolume');
    case 'volume_total':
      return t('common__text__packageVolumeTotal');
    case 'net_weight_cartoon':
      return t('common__text__netWeightPackaging');
    case 'gross_weight_cartoon':
      return t('common__text__grossPackagingWeight');
    case 'net_weight_total':
      return t('common__text__netWeightTotalPackaging');
    case 'gross_weight_total':
      return t('common__text__grossPackagingTotalWeight');
    case 'volume_manual':
      return t('common__text__totalVolume');
    case 'net_weight_manual':
      return t('common__text__totalNetWeight');
    case 'gross_weight_manual':
      return t('common__text__totalGrossWeight');
    case 'pallete_cnt':
      return t('calc__text__packaging__numberOfPalletes');
    case 'pallete_type':
      return t('calc__text__packaging__palletType');
    default:
      return '';
  }
}

export const ACTIVE_COLUMNS_BY_DEFAULT = [
  'id',
  'name',
  'pieces',
  'places_manual',
  'pieces_in_cartoon',
  'unit_price',
  'price',
  'volume_cartoon',
  'volume_total',
  'net_weight_cartoon',
  'gross_weight_cartoon',
  'gross_weight_total',
  'net_weight_total',
  'volume_manual',
  'net_weight_manual',
  'gross_weight_manual',
  'pallete_cnt',
  'pallete_type',
  'settings',
];

export const ACTIVE_COLUMNS_MIN = [
  'id',
  'name',
  'settings',
];
