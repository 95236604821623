import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import AddForm from './components/add_form';
import ModalCode from "../../../modal_code";
import {useLazyGetTnvedQuery} from "../../../../../../../../../api/tnved";

import './customs_declaration.scss';

type Props = {
  isCompleted: boolean,
  declaration: Declaration
  index: number
  count: number
  feesTotal: number
  onSave(): void
  setIsConfirmButtonDisable(value: boolean): void
}

const CustomsDeclaration = ( { isCompleted, declaration, index, onSave, count, feesTotal, setIsConfirmButtonDisable } : Props ) => {

  const [ openModalCode, setOpenModalCode ] = useState(false);

  const [ tnvedInfo, setTnvedInfo ] = useState<TnvedInfo>();

  const [ tnvedRequest ] = useLazyGetTnvedQuery()

  useEffect(() => {
    tnvedRequest(declaration.code)?.then((response) => {
      setTnvedInfo(response?.data);
    });
  }, [])

  return <>
    <AddForm
      index={index}
      declaration={declaration}
      onSave={onSave}
      count={count}
      feesTotal={feesTotal}
      setIsConfirmButtonDisable={setIsConfirmButtonDisable}
      isCompleted={isCompleted}
    />
    <ModalCode
      isOpen={openModalCode}
      onClose={() => setOpenModalCode(false)}
      insideModal
      code={declaration.code}
      tnvedInfo={tnvedInfo}
    />
  </>
  ;
}

export default CustomsDeclaration
