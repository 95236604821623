import React from "react";
import {useTranslation} from "react-i18next";
import cx from "classnames";

import Typography from "@mui/material/Typography";

import Logo from "../../../../../../../../../../../../print/images/logo.svg";
import {COLORS} from "../../../../../../../../../../../../../config/constants";
import {useProductsList} from "../../../../../../../../../../../../../api/calc";

import {getColumnName} from "../../constants";

import './print_table.scss';

type Props = {
  isHorizontal: boolean;
  title: string;
  currentProducts?: string[]
  activeColumns: string[]
  setActiveColumns(id: any): void
}

const PrintTable = React.forwardRef<any, Props>((props, ref) => {

  const { t } = useTranslation()

  const productsList = useProductsList()?.data;

  const hasVolumeCartoon = productsList?.results?.some(product => product.volume_cartoon > 0);
  const hasVolumeManual = productsList?.results?.some(product => product.volume_manual > 0);
  const hasNetWeightManual = productsList?.results?.some(product => product.net_weight_manual > 0);
  const hasGrossWeightManual = productsList?.results?.some(product => product.gross_weight_manual > 0);
  const hasPalleteCnt = productsList?.results?.some(product => product.pallete_cnt > 0);
  const hasPalleteType = productsList?.results?.some(product => product.pallete_type !== null);

  const getHeadInfo = () => {

    const allColumns = [
      {
        id: 'id',
        label: getColumnName('id', t),
      },
      {
        id: 'name',
        label: getColumnName('name', t),
      },
      {
        id: 'pieces',
        label: getColumnName('pieces', t),
      },
      {
        id: 'places_manual',
        label: getColumnName('places_manual', t),
      },
      {
        id: 'pieces_in_cartoon',
        label: getColumnName('pieces_in_cartoon', t),
      },
      {
        id: 'unit_price',
        label: getColumnName('unit_price', t),
      },
      {
        id: 'price',
        label: getColumnName('price', t),
      },
      ...(hasVolumeCartoon
        ? [
          {
            id: 'volume_cartoon',
            label: getColumnName('volume_cartoon', t),
          }
        ]
        : []),
      ...(hasVolumeManual
        ? [
          {
            id: 'volume_manual',
            label: getColumnName('volume_manual', t),
          }
        ]
        : []),
      ...(hasNetWeightManual
        ? [
          {
            id: 'net_weight_manual',
            label: getColumnName('net_weight_manual', t),
          }
        ]
        : []),
      ...(hasGrossWeightManual
        ? [
          {
            id: 'gross_weight_manual',
            label: getColumnName('gross_weight_manual', t),
          }
        ]
        : []),
      {
        id: 'volume_total',
        label: getColumnName('volume_total', t),
      },
      {
        id: 'net_weight_cartoon',
        label: getColumnName('net_weight_cartoon', t),
      },
      {
        id: 'gross_weight_cartoon',
        label: getColumnName('gross_weight_cartoon', t),
      },
      {
        id: 'net_weight_total',
        label: getColumnName('net_weight_total', t),
      },
      {
        id: 'gross_weight_total',
        label: getColumnName('gross_weight_total', t),
      },
      ...(hasPalleteCnt
        ? [
          {
            id: 'pallete_cnt',
            label: getColumnName('pallete_cnt', t),
          }
        ]
        : []),
      ...(hasPalleteType
        ? [
          {
            id: 'pallete_type',
            label: getColumnName('pallete_type', t),
          }
        ]
        : []),
    ];

    return allColumns.filter((column) => props.activeColumns.includes(column.id));
  }

  const rows = productsList?.results.map((productListItem: ProductListItem, index: number) => ({
    ...productListItem,
    id: index + 1,
  }));

  const columnsToShow = getHeadInfo();

  return (
    // @ts-ignore
    <div ref={ref} className={cx('product-table-print__container', {
      'product-table-print__container_isHorizontal': props.isHorizontal,
    })}>
      <img src={Logo} alt='Dita' className='product-table-print__image'/>
      <hr className='product-table-print__hr' />
      <Typography variant='caption' sx={{
        marginTop: 4,
        color: COLORS.BLACK_DARK,
        marginBottom: '6px',
        fontWeight: 500,
        display: 'block',
        letterSpacing: 0,
      }}>
        {props.title}
      </Typography>
      <table className={cx('product-table-print', {
        'product-table-print_isHorizontal': props.isHorizontal,
      })}>
        <thead>
          <tr className='product-table-print__head'>
            {columnsToShow.map(item => <th scope='col' key={item.id}>{item.label}</th>)}
          </tr>
        </thead>
        <tbody className='product-table-print__body'>
          {/* @ts-ignore */}
          {rows.map((row) => <tr key={row.id}>{columnsToShow.map(columnName => <td key={columnName.id}>{row[columnName.id]}</td>)}</tr>)}
        </tbody>
      </table>
    </div>
  )
})

export default PrintTable
