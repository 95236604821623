import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'api'
import {createSlice} from "@reduxjs/toolkit";
import {localizationApi} from "./localization";
import {useSelector} from "react-redux";
import {rootReducer} from "../store";

export const currenciesApi = createApi({
  baseQuery,
  reducerPath: 'currenciesApi',
  endpoints: (builder) => ({
    getDictionaryCurrenciesRate: builder.query<DictionaryParamsOutput[], string>({
      query: () => ({ url: 'dictionary/v4.0/currencies/' }),
    }),
  }),
})

export const currencySlice = createSlice({
  name: 'currencies',
  initialState: {
    currencies: [] as DictionaryParamsOutput[],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      currenciesApi.endpoints.getDictionaryCurrenciesRate.matchFulfilled,
      (state, { payload }) => {
        state.currencies = [ ...state.currencies, ...payload ]
      }
    );
  },
})

export const { useLazyGetDictionaryCurrenciesRateQuery } = currenciesApi

export const useCurrencies = () => {
  return useSelector(({ currencies }: ReturnType<typeof rootReducer>) => currencies.currencies)
}
