import React from "react";
import {useTranslation} from "react-i18next";

import ShowMoreMenu from "../../../../../../../../../../../../common/showMoreMenu";
import {useProductsList} from "../../../../../../../../../../../../../api/calc";
import { getColumnName } from "../../constants";

import SettingsPanel from "../settings_panel";
import ProductsTable from "../table";

import './product_list_table.scss';

type Props = {
    selectedRows?: string[];
    currentProducts?: string[]
    activeColumns: string[]
    onEdit(id: number): void
    onRemove(id: number): void
    handleProductsListRequest(data?: DictionaryParamsInput): void
    chooseProduct(id: string[]): void
    setActiveColumns(id: any): void
}

const ProductListTable = ({
  onEdit,
  onRemove,
  handleProductsListRequest,
  chooseProduct,
  currentProducts,
  activeColumns,
  setActiveColumns
}: Props) => {

  const { t } = useTranslation()

  const handleChangeColumn = (id: string): void => {
    setActiveColumns((prevColumns: string[]) =>
      prevColumns.includes(id)
        ? prevColumns.filter((column) => column !== id)
        : [ ...prevColumns, id ]
    );
  }

  const productsList = useProductsList()?.data;

  const getShowMoreMenu = (id:number) => <ShowMoreMenu menuItems={[
    {
      title: t('actions__text__edit'),
      onClick: () => onEdit(id)
    },
    {
      title: t('actions__text__delete'),
      onClick: () => onRemove(id)
    },
  ]}/>;

  const rows = productsList?.results.map((productListItem: ProductListItem, index: number) => ({
    ...productListItem,
    id: index + 1,
    settings: getShowMoreMenu(productListItem.id)
  }));

  const hasVolumeCartoon = productsList?.results?.some(product => product.volume_cartoon > 0);
  const hasVolumeManual = productsList?.results?.some(product => product.volume_manual > 0);
  const hasNetWeightManual = productsList?.results?.some(product => product.net_weight_manual > 0);
  const hasGrossWeightManual = productsList?.results?.some(product => product.gross_weight_manual > 0);
  const hasPalleteCnt = productsList?.results?.some(product => product.pallete_cnt > 0);
  const hasPalleteType = productsList?.results?.some(product => product.pallete_type !== null);

  const getHeadCells = () => {

    const allColumns = [
      {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: getColumnName('id', t),
      },
      {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: getColumnName('name', t),
      },
      {
        id: 'pieces',
        numeric: false,
        disablePadding: true,
        label: getColumnName('pieces', t),
      },
      {
        id: 'places_manual',
        numeric: false,
        disablePadding: true,
        label: getColumnName('places_manual', t),
      },
      {
        id: 'pieces_in_cartoon',
        numeric: false,
        disablePadding: true,
        label: getColumnName('pieces_in_cartoon', t),
      },
      {
        id: 'unit_price',
        numeric: false,
        disablePadding: true,
        label: getColumnName('unit_price', t),
      },
      {
        id: 'price',
        numeric: false,
        disablePadding: true,
        label: getColumnName('price', t),
      },
      ...(hasVolumeCartoon
        ? [
          {
            id: 'volume_cartoon',
            numeric: false,
            disablePadding: true,
            label: getColumnName('volume_cartoon', t),
          }
        ]
        : []),
      ...(hasVolumeManual
        ? [
          {
            id: 'volume_manual',
            numeric: false,
            disablePadding: true,
            label: getColumnName('volume_manual', t),
          }
        ]
        : []),
      ...(hasNetWeightManual
        ? [
          {
            id: 'net_weight_manual',
            numeric: false,
            disablePadding: true,
            label: getColumnName('net_weight_manual', t),
          }
        ]
        : []),
      ...(hasGrossWeightManual
        ? [
          {
            id: 'gross_weight_manual',
            numeric: false,
            disablePadding: true,
            label: getColumnName('gross_weight_manual', t),
          }
        ]
        : []),
      {
        id: 'volume_total',
        numeric: false,
        disablePadding: true,
        label: getColumnName('volume_total', t),
      },
      {
        id: 'net_weight_cartoon',
        numeric: false,
        disablePadding: true,
        label: getColumnName('net_weight_cartoon', t),
      },
      {
        id: 'gross_weight_cartoon',
        numeric: false,
        disablePadding: true,
        label: getColumnName('gross_weight_cartoon', t),
      },
      {
        id: 'net_weight_total',
        numeric: false,
        disablePadding: true,
        label: getColumnName('net_weight_total', t),
      },
      {
        id: 'gross_weight_total',
        numeric: false,
        disablePadding: true,
        label: getColumnName('gross_weight_total', t),
      },
      ...(hasPalleteCnt
        ? [
          {
            id: 'pallete_cnt',
            numeric: false,
            disablePadding: true,
            label: getColumnName('pallete_cnt', t),
          }
        ]
        : []),
      ...(hasPalleteType
        ? [
          {
            id: 'pallete_type',
            numeric: false,
            disablePadding: true,
            label: getColumnName('pallete_type', t),
          }
        ]
        : []),
      {
        id: 'settings',
        numeric: true,
        disablePadding: true,
        label: <SettingsPanel
          handleSelect={handleChangeColumn}
          activeColumns={activeColumns}
          setActiveColumns={setActiveColumns}
          hasVolumeCartoon={hasVolumeCartoon}
          hasVolumeManual={hasVolumeManual}
          hasNetWeightManual={hasNetWeightManual}
          hasGrossWeightManual={hasGrossWeightManual}
          hasPalleteCnt={hasPalleteCnt}
          hasPalleteType={hasPalleteType}
        />,
      },
    ];

    return allColumns.filter((column) => activeColumns.includes(column.id));
  }

  const getPreferences = () => {

    const allColumns = [
      {
        id: 'id',
        width: 8,
      },
      {
        id: 'name',
        width: 160,
      },
      {
        id: 'pieces',
        width: 68,
      },
      {
        id: 'places_manual',
        width: 60,
      },
      {
        id: 'pieces_in_cartoon',
        width: 68,
      },
      {
        id: 'unit_price',
        width: 80,
      },
      {
        id: 'price',
        width: 90,
      },
      ...(!hasVolumeCartoon ? [] : [
        {
          id: 'volume_cartoon',
          width: 68,
        }
      ]),
      ...(!hasVolumeManual ? [] : [
        {
          id: 'volume_manual',
          width: 68,
        }
      ]),
      ...(!hasNetWeightManual ? [] : [
        {
          id: 'net_weight_manual',
          width: 68,
        }
      ]),
      ...(!hasGrossWeightManual ? [] : [
        {
          id: 'gross_weight_manual',
          width: 68,
        }
      ]),
      {
        id: 'volume_total',
        width: 92,
      },
      {
        id: 'net_weight_cartoon',
        width: 100,
      },
      {
        id: 'gross_weight_cartoon',
        width: 120,
      },
      {
        id: 'net_weight_total',
        width: 120,
      },
      {
        id: 'gross_weight_total',
        width: 120,
      },
      ...(!hasPalleteCnt ? [] : [
        {
          id: 'pallete_cnt',
          width: 68,
        }
      ]),
      ...(!hasPalleteType ? [] : [
        {
          id: 'pallete_type',
          width: 68,
        }
      ]),
      {
        id: 'settings',
        width: 40,
      },
    ];

    return allColumns.filter((column) => activeColumns.includes(column.id));

  }

  return (
    <ProductsTable
      rows={rows}
      count={10}
      preferences={getPreferences()}
      chooseItem={chooseProduct}
      primaryField='name'
      handleSendRequest={handleProductsListRequest}
      hasEmptySelection={!currentProducts?.length}
      headCells={getHeadCells()}
    />
  )
};

export default ProductListTable
