import React from "react";
import {useTranslation} from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Table from '../../../components/table';
import TableRowActions from '../../../components/table_row_actions';
import isArray from 'lodash/isArray'

import {useLocations } from "../../../../../../api/dictionary";
import { ACTIONS } from "../../../constants";

import './table.scss';

type Props = {
  selectedRows?: string[];
  currentLocations?: string[]
  handleEdit(): void
  handleRemove(): void
  handleLocationsRequest(data?: DictionaryParamsInput): void
  changeLocation(id: string[]): void
}

const LocationTable = ({ handleEdit, handleRemove, handleLocationsRequest, changeLocation, currentLocations }: Props) => {

  const { t } = useTranslation()

  const locations = useLocations()?.data;

  const headCells = [
    {
      id: 'code',
      numeric: false,
      disablePadding: false,
      label: t('common__text__code'),
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: t('common__text__location'),
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: t('common__text__type'),
    },
  ];

  const preferences = [
    {
      id: 'code',
      width: 150,
    },
    {
      id: 'name',
      width: 190,
    },
    {
      id: 'type',
      width: 200,
    },
  ];

  if (!isArray(locations.results)){
    return null;
  }

  return (
    <div className='location-table'>
      <Table
        rows={locations.results}
        count={locations.count}
        headCells={headCells}
        preferences={preferences}
        chooseItem={changeLocation}
        primaryField='id'
        handleSendRequest={handleLocationsRequest}
        hasEmptySelection={!currentLocations?.length}
        actionsAfterTable={(selected: string[]) =>
          <TableRowActions hasEmptySelection={!selected.length}>
            {selected.length === 1 && <MenuItem
              value={ACTIONS.EDIT}
              onClick={handleEdit}
            >{t('actions__text__edit')}</MenuItem>}
            <MenuItem
              value={ACTIONS.REMOVE}
              onClick={handleRemove}
            >{t('actions__text__delete')}</MenuItem>
          </TableRowActions>}
      />
    </div>
  )
};

export default LocationTable
