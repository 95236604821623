import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'api'
import { useSelector } from "react-redux";
import { rootReducer } from "../store";
import {createSlice} from "@reduxjs/toolkit";

export const localizationApi = createApi({
  baseQuery,
  reducerPath: 'localizationApi',
  endpoints: (builder) => ({
    getDictionaryLanguages: builder.query<DictionaryParamsOutput[], string>({
      query: () => `localization/v1.0/languages/`,
    }),
    getDictionaryTimezones: builder.query<DictionaryParamsOutput[], string>({
      query: () => `localization/v1.0/timezones/`,
    })
  }),
})

export const localizationSlice = createSlice({
  name: 'localization',
  initialState: {
    languages: [] as DictionaryParamsOutput[],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      localizationApi.endpoints.getDictionaryLanguages.matchFulfilled,
      (state, { payload }) => {
        state.languages = [ ...state.languages, ...payload ]
      }
    );
  },
})

export const {
  useLazyGetDictionaryLanguagesQuery,
  useLazyGetDictionaryTimezonesQuery,
} = localizationApi

export const useLanguages = () => {
  return useSelector(({ localization }: ReturnType<typeof rootReducer>) => localization.languages)
}
