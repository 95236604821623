import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import {useCurrencies, useLazyGetCurrenciesQuery, useUpdateCurrencies} from "../../../../../api/dictionary";
import SearchField from "../../components/search_field";
import Header from './../../components/header';
import Preloader from "../../../../common/preloader";
import {INITIAL_DATA_REQUEST} from "../../constants";

import CurrencyTable from "./table";
import CurrencyActions from "./actions";

import './currency.scss';

const Currency = () => {

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [ currentCurrencies, setCurrentCurrencies ] = useState<string[]>([])

  const [ showModalRemoveByDefault, setShowModalRemoveByDefault ] = useState(false)
  const [ showModalAddByDefault, setShowModalAddByDefault ] = useState(false)

  const [ hasLoadedFirstRequest, setHasLoadedFirstRequest ] = useState(false)

  const [ currenciesRequest, { isLoading: isLoadingCurrencies } ] = useLazyGetCurrenciesQuery()

  const currenciesParams = useCurrencies()?.params;

  const currencyRequestUpdate = (params?: DictionaryParamsInput) => {
    return currenciesRequest({...currenciesParams, ...params});
  }

  useEffect(() => {
    currenciesRequest(INITIAL_DATA_REQUEST).then(() =>
      setHasLoadedFirstRequest(true)
    )
  }, [])


  useEffect(() => {
    return () => {
      // @ts-ignore
      dispatch(useUpdateCurrencies());
    };
  }, []);

  const currencyRequestSearch = (query: string) => currenciesRequest({
    ...currenciesParams,
    search: query,
  })

  const handleEdit = () => {
    setShowModalAddByDefault(true);
  }

  const handleOpenRemoveModal = () => setShowModalRemoveByDefault(true)

  const handleCloseModal = () => {
    setCurrentCurrencies([]);
    setShowModalRemoveByDefault(false);
    setShowModalAddByDefault(false);
  }

  const isLoadingStatus = isLoadingCurrencies && !hasLoadedFirstRequest;

  return (
    <>
      <div>
        <Header menuText={t('common__text__currency')}/>
        {isLoadingStatus ? <Preloader/> : <div className='currency-container'>
          <Card>
            <CardContent sx={{ padding: 0, '&:last-child' : { paddingBottom: 0 } }}>
              <div className='currency-container__search-container'>
                <SearchField onSearch={currencyRequestSearch}  />
              </div>
              <CurrencyTable
                handleEdit={handleEdit}
                handleRemove={handleOpenRemoveModal}
                handleCurrenciesRequest={currencyRequestUpdate}
                changeCurrency={setCurrentCurrencies}
                currentCurrencies={currentCurrencies}
              />
            </CardContent>
          </Card>
        </div>}
      </div>
      {!isLoadingStatus && (
        <div>
          <CurrencyActions
            currencyRequestUpdate={currencyRequestUpdate}
            isOpenRemoveByDefault={showModalRemoveByDefault}
            isOpenAddByDefault={showModalAddByDefault}
            currentCurrencies={currentCurrencies}
            onCloseModal={handleCloseModal}
          />
        </div>
      )}
    </>
  )
};

export default Currency
