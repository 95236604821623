import React from "react";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: string ) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    orderBy: string;
    rowCount: number;
    headCells: HeaderCell[];
    count: number;
}

export type HeaderCell = {
    id: string
    numeric: boolean
    disablePadding: boolean
    label: React.ReactNode
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { orderBy, onRequestSort, headCells } =
        props;

  const getArrowDirection = (headCell: HeaderCell) => {
    if (orderBy.includes(headCell.id)){
      return headCell.id === orderBy ? 'desc' : 'asc';
    }

    return undefined
  }

  const getPadding = (index: number, length: number) => {
    switch (index) {
      case 0:
        return '16px';
      case length - 1:
        return '16px 24px 16px 0';
      default:
        return '16px 16px 16px 0';
    }
  }

  const handleClickSort = (event: React.MouseEvent<unknown>, headCell: HeaderCell) => {
    onRequestSort(event, headCell.id == orderBy ? `-${headCell.id}` : headCell.id)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => <TableCell
          key={headCell.id}
          align={(index !== 0 && index !== 1) ? 'right' : 'left'}
          padding={headCell.disablePadding ? 'none' : 'normal'}
          sortDirection={getArrowDirection(headCell)}
          sx={{
            padding: getPadding(index, headCells.length),
            '.MuiTableSortLabel-icon': {
              display: index + 1 === headCells.length ? 'none' : 'inline-block',
            }
          }}
        >
          <TableSortLabel
            active={orderBy.includes(headCell.id)}
            direction={getArrowDirection(headCell)}
            sx={{ fontWeight: '500', lineHeight: '20px' }}
            onClick={(event) => handleClickSort(event, headCell)}
          >
            {headCell.label}
          </TableSortLabel>
        </TableCell>)}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead
