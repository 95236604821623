import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import {useCurrencies, useLazyGetIncotermsQuery, useUpdateIncoterms} from "../../../../../api/dictionary";
import SearchField from "../../components/search_field";
import Header from './../../components/header';
import Preloader from "../../../../common/preloader";
import {INITIAL_DATA_REQUEST} from "../../constants";

import IncotermsTable from "./table";
import IncotermsActions from "./actions";

import './incoterms.scss';


const Incoterms = () => {

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [ currentIncoterms, setCurrentIncoterms ] = useState<string[]>([])

  const [ showModalRemoveByDefault, setShowModalRemoveByDefault ] = useState(false)
  const [ showModalAddByDefault, setShowModalAddByDefault ] = useState(false)

  const [ hasLoadedFirstRequest, setHasLoadedFirstRequest ] = useState(false)

  const [ incotermsRequest, { isLoading: isLoadingIncoterms } ] = useLazyGetIncotermsQuery()

  const incotermsParams = useCurrencies()?.params;

  const incotermsRequestUpdate = (params?: DictionaryParamsInput) =>
    incotermsRequest({...incotermsParams, ...params});

  useEffect(() => {
    incotermsRequestUpdate().then(() =>
      setHasLoadedFirstRequest(true)
    )
  }, [])

  useEffect(() => {
    incotermsRequest(INITIAL_DATA_REQUEST);
    return () => {
      // @ts-ignore
      dispatch(useUpdateIncoterms());
    };
  }, []);


  const incotermsRequestSearch = (query: string) => incotermsRequest({
    ...incotermsParams,
    search: query,
  })

  const handleEdit = () => {
    setShowModalAddByDefault(true);
  }

  const handleOpenRemoveModal = () => setShowModalRemoveByDefault(true)

  const handleCloseModal = () => {
    setCurrentIncoterms([]);
    setShowModalRemoveByDefault(false);
    setShowModalAddByDefault(false);
  }

  const isLoadingStatus = isLoadingIncoterms && !hasLoadedFirstRequest;

  return (
    <>
      <div>
        <Header menuText={t('menu__text__incoterms')}/>
        {isLoadingStatus ? <Preloader/> : <div className='incoterms-container'>
          <Card>
            <CardContent sx={{ padding: 0, '&:last-child' : { paddingBottom: 0 } }}>
              <div className='incoterms-container__search-container'>
                <SearchField onSearch={incotermsRequestSearch}  />
              </div>
              <IncotermsTable
                handleEdit={handleEdit}
                handleRemove={handleOpenRemoveModal}
                handleIncotermsRequest={incotermsRequestUpdate}
                changeIncoterm={setCurrentIncoterms}
                currentIncoterms={currentIncoterms}
              />
            </CardContent>
          </Card>
        </div>}
      </div>
      {!isLoadingStatus && (
        <div>
          <IncotermsActions
            incotermsRequestUpdate={incotermsRequestUpdate}
            isOpenRemoveByDefault={showModalRemoveByDefault}
            isOpenAddByDefault={showModalAddByDefault}
            currentIncoterms={currentIncoterms}
            onCloseModal={handleCloseModal}
          />
        </div>
      )}
    </>
  )
};

export default Incoterms
