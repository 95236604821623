import React from "react";
import {useTranslation} from "react-i18next";
import cx from "classnames";

import Typography from "@mui/material/Typography";

import PreferencesRadioButton from "./components/preferencesRadioButton";
import PreferencesText from "./components/preferencesText";

import './preferences-choose.scss';

type PreferencesProps = {
  code: string
  handleChangePreferences(checked: boolean): void
}

const PreferencesChoose = ({code, handleChangePreferences}: PreferencesProps) => {

  const { t } = useTranslation()

  return <div className={cx('preferences')}>
    <Typography variant='subtitle1' sx={{ fontWeight: 500, marginBottom: 2 }}>
      {`${t('calc__text__customs__preferencesForTheHSCode')} ${code}`}
    </Typography>
    <PreferencesText />
    <PreferencesRadioButton handleChangePreferences={handleChangePreferences} />
  </div>
}

export default PreferencesChoose
