import React from "react";
import {useTranslation} from "react-i18next";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";

import Completed from "../../../../../../../../common/completed";
import {COLORS} from "../../../../../../../../../config/constants";

import './preferences-completed.scss';

type PreferencesProps = {
  code: string
  hasPreferences: boolean
  handleEdit(): void
}

const PreferencesCompleted = ({hasPreferences, code, handleEdit}: PreferencesProps) => {

  const { t } = useTranslation()

  const getCompletedInfo = () => [
    {
      label: t('calc__text__customs__allNecessaryConditionsAreFulfilled'),
      value: hasPreferences ?
        <span className='preferences-value'>
          <CheckCircleIcon  sx={{
            color: COLORS.GREEN,
            marginRight: '4px',
            width: '20px'
          }}/>{t('calc__text__customs__confirmPreferences')}</span>
        : <span className='preferences-value'>
          <NotInterestedOutlinedIcon sx={{
            color: COLORS.MEDIUM_GRAY,
            marginRight: '4px',
            width: '20px'
          }}/>
          {t('calc__text__customs__refusePreferences')}
        </span>
    }
  ]

  return <div className='preferences-completed'>
    <Completed
      info={getCompletedInfo()}
      isFullRow
      caption={`${t('calc__text__customs__preferencesForTheHSCode')} ${code}`}
      onEdit={handleEdit}
      noBackground
    />
  </div>
}

export default PreferencesCompleted
