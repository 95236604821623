import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import {useLazyGetDictionaryLanguagesQuery, useLazyGetDictionaryTimezonesQuery} from "../../../../../api/localization";
import Preloader from "../../../../common/preloader";
import {useUpdateUserInfoMutation, useUser} from "../../../../../api/auth";
import AlertBottom from "../../../../common/alertBottom";

import './interface.scss'

enum INTERFACE_FORM {
    LANG = 'lang',
    TIMEZONE = 'timezone',
}

type InterfaceForm = {
    lang: string
    timezone: string
}

const Interface = () => {

  const { t } = useTranslation()
  const { i18n } = useTranslation();

  const [ localizationGetLanguagesRequest, { data: languages } ] = useLazyGetDictionaryLanguagesQuery();
  const [ localizationGetTimezonesRequest, { data: timezones } ] = useLazyGetDictionaryTimezonesQuery();

  const [ openAlertSaved, setOpenAlertSaved ] = useState(false);
  const [ openAlertError, setOpenAlertError ] = useState(false);

  const { register, handleSubmit, setValue, formState: { errors } } = useForm<InterfaceForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange'
  })

  const [ updateUserInfo ] = useUpdateUserInfoMutation()

  const user = useUser();

  const lang = user.lang;
  const timezone = user.timezone;

  useEffect(() => {
    localizationGetLanguagesRequest('');
    // @ts-ignore
    localizationGetTimezonesRequest().then((data) => setValue(INTERFACE_FORM.TIMEZONE, data.data[0].code));
  }, []);

  useEffect(() => {
    // @ts-ignore
    setValue(INTERFACE_FORM.LANG, lang);
    // @ts-ignore
    setValue(INTERFACE_FORM.TIMEZONE, timezone);
  }, [ user ]);

  const languagesOptions = (languages ?? [])
    .map(({ name, code }: DictionaryParamsOutput) => {
      return ({ id: code, label: name});
    })

  const selectedValueLang = React.useMemo(
    // @ts-ignore
    () => languagesOptions.find((value) => value.id === lang),
    [ languagesOptions ],
  );

  const timezoneOptions = (timezones ?? [])
    .map(({ city, code, tzoffset }: DictionaryParamsOutput) => {
      return ({ id: code, label: `${city}, ${code}, ${tzoffset}`});
    })

  const selectedValueTimezone = React.useMemo(
    // @ts-ignore
    () => timezoneOptions.find((value) => value.id === timezone),
    [ timezoneOptions ],
  );

  if (!languages?.length || !timezones?.length) {
    return <Preloader />;
  }

  const checkIsValid = () => isEmpty(errors)

  const handleChangeLanguage = (value: string) => {
    i18n.changeLanguage(value.toLowerCase());
    moment.locale(value.toLowerCase());
  }

  const onSubmit = handleSubmit (async (values) => {
    await updateUserInfo(values).then((result) => {
      // @ts-ignore
      if (result?.data){
        setOpenAlertSaved(true)
        if (values.lang !== lang){
          handleChangeLanguage(values.lang);
        }
      } else {
        setOpenAlertError(true);
      }
    });
  })

  return (
    <>
      <form className='settings-interface' onSubmit={onSubmit}>
        <Typography
          variant='h6'
          className='settings-interface__title'
        >
          {t('settings__text__interfaceLanguageTimezone')}
        </Typography>
        <Autocomplete
          className={`settings-interface__${INTERFACE_FORM.LANG}`}
          options={languagesOptions}
          noOptionsText={t('calc__text__main__noOptionsFound')}
          value={selectedValueLang}
          {...register(INTERFACE_FORM.LANG, {required: true})}
          // @ts-ignore
          onChange={(_, value: { id: string } | null) => {
            // @ts-ignore
            setValue(INTERFACE_FORM.LANG, value?.id);
          }}
          loadingText={t('common__message__loadingText')}
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(errors[INTERFACE_FORM.LANG])}
              variant='outlined'
              label={t('settings__text__interfaceLanguage')}
              InputProps={{
                ...params.InputProps,
              }}
              InputLabelProps={{shrink: true}}
            />)}/>
        <Autocomplete
          className={`settings-interface__${INTERFACE_FORM.TIMEZONE}`}
          options={timezoneOptions}
          noOptionsText={t('calc__text__main__noOptionsFound')}
          value={selectedValueTimezone}
          {...register(INTERFACE_FORM.TIMEZONE, {required: true})}
          // @ts-ignore
          onChange={(_, value: { id: string } | null) => setValue(INTERFACE_FORM.TIMEZONE, value?.id)}
          loadingText={t('common__message__loadingText')}
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(errors[INTERFACE_FORM.TIMEZONE])}
              variant='outlined'
              label={t('settings__text__timezone')}
              InputProps={{
                ...params.InputProps,
              }}
              InputLabelProps={{shrink: true}}
            />)}/>
        <Button
          className='settings-auth__button'
          variant='contained'
          fullWidth
          type='submit'
          startIcon={<CheckIcon/>}
          disabled={!checkIsValid()}
        >{t('actions__text__saveInterfaceSettings')}</Button>
      </form>
      <AlertBottom
        open={openAlertSaved}
        onClose={() => setOpenAlertSaved(false)}
        message={t('actions__message__settingsWereSavedSuccessfully')}
      />
      <AlertBottom
        isError
        open={openAlertError}
        onClose={() => setOpenAlertError(false)}
        message={t('common__text__error')}
      />
    </>
  )
}

export default Interface
