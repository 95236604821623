import React, {useState} from "react";
import isUndefined from 'lodash/isUndefined'

import PreferencesChoose from "./components/preferencesChoose";
import PreferencesCompleted from "./components/preferencesCompleted";

type PreferencesProps = {
    code: string
    hasPreferences?: boolean
    handleChangePreferences(checked: boolean): void
}

const Preferences = ({code, handleChangePreferences, hasPreferences }: PreferencesProps) => {

  const [ shouldShowPreferencesChoose, setShouldShowPreferencesChoose ] = useState(isUndefined(hasPreferences));

  if (shouldShowPreferencesChoose){
    return <PreferencesChoose code={code} handleChangePreferences={handleChangePreferences}/>
  }

  return <PreferencesCompleted
    hasPreferences={Boolean(hasPreferences)}
    code={code}
    handleEdit={() => setShouldShowPreferencesChoose(true)}
  />

}

export default Preferences
