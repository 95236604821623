import {useTranslation} from "react-i18next";

import {ORDER_FORM} from "../../../../cabinet/calculator/components/main/components/editMain";

export const getMainInfo = (order?: OrderParamsOutput) => {

  const { t } = useTranslation();

  if (!order){
    return []
  }

  return [
    [
      ...(order?.[ORDER_FORM.COMMENT]
        ? [
          {
            label: t('common__text__comment'),
            value: order?.[ORDER_FORM.COMMENT],
          },
        ]
        : []),
      ...(order?.[ORDER_FORM.BASE_CURRENCY]
        ? [
          {
            label: t('common__text__currency'),
            value: order?.[ORDER_FORM.BASE_CURRENCY],
          },
        ]
        : []),
    ]
  ]
}
