import React from "react";
import {useTranslation} from "react-i18next";

import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import Divider from "@mui/material/Divider";

import {ACTIVE_COLUMNS_BY_DEFAULT, ACTIVE_COLUMNS_MIN, getSettingsColumnName} from "../../constants";

import './settings_panel.scss';

type Props = {
  activeColumns: string[]
  hasVolumeCartoon: boolean
  hasVolumeManual: boolean
  hasNetWeightManual: boolean
  hasGrossWeightManual: boolean
  hasPalleteCnt: boolean
  hasPalleteType: boolean
  setActiveColumns(ids: string[]): void
  handleSelect(id: string): void
}

const SettingsPanel = ({
  handleSelect,
  activeColumns,
  setActiveColumns,
  hasVolumeCartoon,
  hasVolumeManual,
  hasNetWeightManual,
  hasGrossWeightManual,
  hasPalleteCnt,
  hasPalleteType
}: Props) => {

  const { t } = useTranslation()

  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);

  const [ selectedAll, setSelectedAll ] = React.useState(true);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeSelectAll = () => {
    setActiveColumns(selectedAll ? [ ...ACTIVE_COLUMNS_MIN ] : [ ...ACTIVE_COLUMNS_BY_DEFAULT ])
    setSelectedAll(!selectedAll);
  }

  return <>
    <IconButton
      aria-label='ru'
      sx={{ color: 'rgba(0, 0, 0, 0.54)', padding: '12px 0'}}
      aria-controls={open ? 'settings-panel' : undefined}
      aria-haspopup
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
    >
      <SettingsIcon />
    </IconButton>
    <Menu
      id='settings-panel'
      aria-labelledby='settings-panel'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={{
        '.MuiPaper-rounded': {
          margin: 0,
        },
        '.MuiButtonBase-root': {
          minHeight: '40px'
        }
      }}
    >
      <Paper
        className='settings-panel'
        sx={{
          margin: 0,
          width: '320px',
        }}
      >
        <div className='settings-panel__container'>
          {ACTIVE_COLUMNS_BY_DEFAULT
            .filter((columnId) => ![ 'id', 'name', 'settings' ]
              .includes(columnId) &&
                (columnId !== 'volume_cartoon' || hasVolumeCartoon) &&
                (columnId !== 'volume_manual' || hasVolumeManual) &&
                (columnId !== 'net_weight_manual' || hasNetWeightManual) &&
                (columnId !== 'gross_weight_manual' || hasGrossWeightManual) &&
                (columnId !== 'pallete_cnt' || hasPalleteCnt) &&
                (columnId !== 'pallete_type' || hasPalleteType)
            ).map((columnId, index) => (
              <FormControlLabel
                key={columnId}
                control={<Checkbox
                  checked={activeColumns.includes(columnId)}
                  onChange={() => handleSelect(columnId)}
                  sx={{
                    padding: '0 8px',
                  }}
                />}
                sx={{
                  marginLeft: '4px',
                  '.MuiFormControlLabel-label': {
                    fontSize: '16px'
                  }
                }}
                label={getSettingsColumnName(columnId, t)}
              />
            ))}
        </div>
        <Divider sx={{ marginTop: 0 }}/>
        <div className='settings-panel__container'>
          <FormControlLabel
            key='select-all'
            control={<Checkbox
              checked={selectedAll}
              onChange={handleChangeSelectAll}
              sx={{
                padding: '0 8px',
              }}
            />}
            sx={{
              marginLeft: '4px',
              '.MuiFormControlLabel-label': {
                fontSize: '16px'
              }
            }}
            label={`${t('calc__text__packaging__showHideEverything')}`}
          />
        </div>
      </Paper>
    </Menu>
  </>

}

export default SettingsPanel
