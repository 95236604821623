import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {useReactToPrint} from "react-to-print";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import {
  useProductsList,
  useUpdateProductsList,
  INITIAL_DATA_REQUEST,
  useLazyProductsDownloadExcelByInvoiceIdQuery,
  useLazyProductsSearchByInvoiceIdQuery,
} from "../../../../../../../../../../../api/calc";
import Preloader from "../../../../../../../../../../common/preloader";

import SearchField from "./components/search_field";
import ProductListTable from "./components/product_list_table";
import PrintTable from "./components/print_table";
import {ACTIVE_COLUMNS_BY_DEFAULT} from "./constants";

import './products_table.scss';

type Props = {
  invoiceId: number;
  onEdit(id: number): void
  onRemove(id: number): void
  title: string
}

const enum ACTIONS {
  DOWNLOAD_EXCEL = 'DOWNLOAD_EXCEL',
  DOWNLOAD_PDF_VERTICAL = 'DOWNLOAD_PDF_VERTICAL',
  DOWNLOAD_PDF_HORIZONTAL = 'DOWNLOAD_PDF_HORIZONTAL',
}

const enum PRINT_ORIENTATION {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
}

const ProductsTable = ({invoiceId, onEdit, onRemove, title}: Props) => {

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [ currentProductsList, setCurrentProductsList ] = useState<string[]>([])

  const [ action, setAction ] = React.useState<string>('');

  const [ productsListRequest, { isLoading: isLoadingProductsListRequest } ] = useLazyProductsSearchByInvoiceIdQuery()

  const [ downloadExcelRequest ] = useLazyProductsDownloadExcelByInvoiceIdQuery();

  const productsListParams = useProductsList()?.params;

  const [ hasLoadedFirstRequest, setHasLoadedFirstRequest ] = useState(false)
  const [ printOrientation, setPrintOrientation ] = useState<PRINT_ORIENTATION | null>(null)

  const [ activeColumns, setActiveColumns ] = useState<string[]>(ACTIVE_COLUMNS_BY_DEFAULT)

  const componentRef = useRef();

  const productsListRequestUpdate = (params?: DictionaryParamsInput) => {
    return productsListRequest({...productsListParams, ...params});
  }

  useEffect(() => {
    productsListRequest({...INITIAL_DATA_REQUEST, invoiceId: invoiceId }).then(() =>
      setHasLoadedFirstRequest(true)
    )
  }, [])

  useEffect(() => {
    return () => {
      // @ts-ignore
      dispatch(useUpdateProductsList());
    };
  }, []);

  useEffect(() => {
    if (printOrientation !== null) {
      handlePrint();
    }
  }, [ printOrientation ]);

  const productsListRequestSearch = (query: string) => productsListRequest({
    ...productsListParams,
    query,
  })

  const isLoadingStatus = isLoadingProductsListRequest && !hasLoadedFirstRequest;

  const downloadExcel = async () => {
    try {
      const { blob, filename } = await downloadExcelRequest({ invoiceId }).unwrap();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed:", error);
    }
  }

  const getPageMargins = () => {
    const printPageOrientation = printOrientation === PRINT_ORIENTATION.VERTICAL ? 'portrait' : 'landscape';
    return `@page { margin: 32px 48px !important; size: A4 ${printPageOrientation}; }`;
  }

  const handlePrintVertical = () => {
    setPrintOrientation(PRINT_ORIENTATION.VERTICAL);
    handlePrint();
  }

  const handlePrintHorizontal = () => {
    setPrintOrientation(PRINT_ORIENTATION.HORIZONTAL);
    handlePrint();
  }

  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => componentRef.current,
    documentTitle: title
  });

  return (
    <>
      <div className='product-table-container'>
        {isLoadingStatus ? <Preloader/> : <Card sx={{width: '100%'}}>
          <CardContent sx={{padding: 0, '&:last-child': {paddingBottom: 0}}}>
            <div className='product-table-container__search-container'>
              <SearchField onSearch={productsListRequestSearch}/>
            </div>
            <ProductListTable
              onEdit={onEdit}
              onRemove={onRemove}
              handleProductsListRequest={productsListRequestUpdate}
              chooseProduct={setCurrentProductsList}
              currentProducts={currentProductsList}
              setActiveColumns={setActiveColumns}
              activeColumns={activeColumns}
            />
            <div className='product-table-container__actions'>
              <FormControl
                sx={{width: '344px'}}>
                <InputLabel id='action-select'>{t('actions__text__selectAction')}</InputLabel>
                <Select
                  labelId='action-select'
                  id='action-select'
                  value={action}
                  label={t('actions__text__selectAction')}
                  onChange={({target: {value}}) => setAction(value)}
                >
                  <MenuItem
                    value={ACTIONS.DOWNLOAD_EXCEL}
                    onClick={downloadExcel}
                  >{t('common__text__downloadExcel')}</MenuItem>
                  <MenuItem
                    value={ACTIONS.DOWNLOAD_PDF_VERTICAL}
                    onClick={handlePrintVertical}
                  >{t('common__text__downloadPDFVertical')}</MenuItem>
                  <MenuItem
                    value={ACTIONS.DOWNLOAD_PDF_HORIZONTAL}
                    onClick={handlePrintHorizontal}
                  >{t('common__text__downloadPDFHorizontal')}</MenuItem>
                </Select>
              </FormControl>
            </div>
          </CardContent>
        </Card>}
      </div>
      <PrintTable
        ref={componentRef}
        isHorizontal={printOrientation === PRINT_ORIENTATION.HORIZONTAL}
        title={title}
        currentProducts={currentProductsList}
        setActiveColumns={setActiveColumns}
        activeColumns={activeColumns}
      />
      <style>{getPageMargins()}</style>
    </>
  )
};

export default ProductsTable
