import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Rating from '@mui/material/Rating';
import {useTranslation} from "react-i18next";
import {
  useAddServicesToRouteByOrderIdMutation,
  useChooseRouteByOrderIdMutation, useCurrentRoute
} from '../../../../../../../api/calc'
import {useState} from "react";

import './card.scss'
import {COLORS} from "../../../../../../../config/constants";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import React from "react";
import Services from './services';
import Map from './map';
import Route from './route';
import Divider from "@mui/material/Divider";
import DeleteIcon from '@mui/icons-material/Delete';
import moment from "moment";
import uniqBy from "lodash/uniqBy";
import {useGetCalcIdFromRoute} from "../../../../../../../utils/hooks";
import cx from "classnames";
import uniq from "lodash/uniq";
import {numberWithSpaces} from "../../../../../../../utils/helpers";

type Props = {
  isActive?: boolean
  isChosen?: boolean
  route?: Route,
  currentRouteRef?: any,
  refetch(): void,
  handleRemove(): void,
  handleClose?(): void,
}

const DeliveryCard = ({ isActive, route, refetch, currentRouteRef, handleRemove, handleClose, isChosen } : Props ) => {

  const currentRoute = useCurrentRoute()

  const [ activeAddServicesIds, setActiveAddServicesIds ] = useState<number[]>(currentRoute ? currentRoute.selected_services : [])

  const [ currentRouteId, setCurrentRouteId ] = useState<number | undefined>()

  const [ isChanged, setIsChanged ] = useState(false)

  const [ showMore, setShowMore ] = useState(false)

  const { t } = useTranslation()

  const BENEFITS = {
    'fastest': {
      text: t('calc__text__delivery__theFastest'),
      background: '#9575CD',
    },
    'cheapest': {
      text: t('calc__text__delivery__theCheapest'),
      background: '#4DB6AC',
    },
    'popular': {
      text: t('calc__text__delivery__userSelection'),
      background: '#AED581',
    },
  }

  const [ chooseRoute ] = useChooseRouteByOrderIdMutation()

  const [ addServices ] = useAddServicesToRouteByOrderIdMutation()

  const orderId = useGetCalcIdFromRoute()

  if (!route) {
    return null;
  }

  const getRouteDocuments = () => {
    let documents: { link: string, title: string }[] = [];

    route?.freights?.map((freight) => {
      if (freight?.documents){
        documents = [ ...documents, ...freight.documents ];
      }
    })

    return documents;
  }

  const getRouteServices = () => {
    const services: { name: string, price: string, id?: number, status: number }[] = [];

    route?.freights?.map((freight) => {
      freight.services.map((service: Service) => {
        services.push({
          id: service.id,
          name: service.name,
          price: `${service.value} ${service.currency}`,
          status: service.status
        })
      })
    })

    const tariffServices = services.filter(service => !service.id)
    const addServices = services.filter(service => service.id)

    return [ ...tariffServices, ...uniqBy(addServices, 'id') ];
  }

  const handleChoose = async () => {

    if (isActive || isChanged) {
      handleRemove();
      return;
    }

    const data = {
      orderId,
      route: route.id,
      services: (currentRoute?.id === route.id || currentRouteId === route.id) ? activeAddServicesIds : [],
      planned_shipment_date: route.planned_shipment_date,
      planned_delivery_date: route.planned_delivery_date,
      price_expired_date: route.price_expired_date
    }
    await chooseRoute(data).then((response) => {
      setIsChanged(true);
      // @ts-ignore
      if (response?.data.route){
        setCurrentRouteId(route.id);
        refetch()
        setTimeout(() => {
          currentRouteRef?.current?.scrollIntoView({ block:'nearest', behavior: 'smooth' });
        }, 500);
      }
    })

    handleClose && handleClose();
  }

  const companyName = route?.freights?.[0]?.delivery_company?.name;

  const routeServices = getRouteServices();
  const routeDocuments = getRouteDocuments();

  const shouldShowServicesAccordion = routeServices.length > 0 || routeDocuments.length > 0;

  return <>
    {route.labels && <div className='delivery-benefits'>
      {route.labels.map((routeBenefit: string, index) =>
        <Card className='delivery-benefit'
          key={index}
          sx={{
            borderRadius: 0,
            boxShadow: 'none',
            // @ts-ignore,
            background: BENEFITS[routeBenefit].background,
            color: COLORS.WHITE
          }}>
          <Typography variant='subtitle2'>
            {/*@ts-ignore*/}
            {BENEFITS[routeBenefit].text}
          </Typography>
        </Card>
      )}
    </div>}
    <Card className={cx('delivery-card', {
      'delivery-card_isChosen': isChosen,
    })} sx={{ background: isActive ? COLORS.LIME : COLORS.WHITE, borderRadius: 0 }}>
      <CardContent sx={{
        padding: 0, '&:last-child': {
          paddingBottom: 0
        }
      }}>
        <div className='delivery-card__layout'>
          <div className='delivery-card__main-content'>
            <div className='delivery-card__main-info'>
              <span className='delivery-card__logo'>{companyName.substring(0, 1)}</span>
              <div>
                <Typography variant='subtitle2'>
                  {t('common__text__company')} {companyName}
                </Typography>
                {/*<div className='delivery-card__rating-container'>*/}
                {/*  <Rating name='read-only' value={2} readOnly size='small' />*/}
                {/*  <Typography variant='caption' sx={{ color: 'rgba(0, 0, 0, 0.38)', marginLeft: '4px' }}>*/}
                {/*    (1234)*/}
                {/*  </Typography>*/}
                {/*</div>*/}
              </div>
            </div>
            <Typography variant='caption' sx={{color: COLORS.LIGHT_TEXT_SECONDARY, marginTop: 2, display: 'block'}}>
              {t('calc__text__delivery__routeNumber')}: {route.code}
            </Typography>
            <Route route={route}/>
          </div>
          <div className='delivery-card__sum'>
            <Typography variant='h6'>
              {numberWithSpaces(route.total_price.toFixed(2))} USD
            </Typography>
            <Typography variant='caption' sx={{ color: COLORS.LIGHT_TEXT_SECONDARY }}>
              {t('calc__text__delivery__priceIsAvailable')}
              <br/>{t('common__text__before')} {moment(route.price_expired_date).format('DD.MM.YYYY')}
            </Typography>
          </div>
        </div>
        {shouldShowServicesAccordion && <Services
          isActive={isActive}
          services={getRouteServices()}
          documents={getRouteDocuments()}
          defaultExpanded={showMore}
          handleClickAccordion={(value) => setShowMore(value)}
        />}
        {/*<Map isActive={isActive} />*/}
        <div className='delivery-card__confirmButton'>
          <Button
            variant='contained'
            fullWidth
            onClick={handleChoose}
            startIcon={isActive || isChanged ? <DeleteIcon sx={{color: COLORS.WHITE}}/> :
              <CheckIcon sx={{color: COLORS.WHITE}}/>}
            sx={{
              marginBottom: '12px',
            }}
          >{isActive || isChanged ? t('calc__text__delivery__cancelDeliverySelection') : t('calc__text__delivery__chooseDelivery')}</Button>
        </div>
      </CardContent>
    </Card>
  </>
}

export default DeliveryCard
