import Button from '@mui/material/Button'
import './modalSmall.scss'

import { useTranslation } from "react-i18next"
import Typography from "@mui/material/Typography";
import React, {ReactNode} from "react";
import ModalBig from "../modalBig";
import DeleteIcon from '@mui/icons-material/Delete';

type Props = {
    onContinue: (index?: number | string) => void,
    onClose: () => void;
    isOpen: boolean,
    index?: number | string,
    buttonYesText: string,
    title: string,
    description: ReactNode
}

const ModalAction = ({ onContinue, isOpen, index, onClose, title, description, buttonYesText }: Props) => {

  const { t } = useTranslation()

  const handleClickYes = () => index ? onContinue(index) : onContinue()

  return (
    <ModalBig
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      width={736}
    >
      <Typography variant='body1' className='modal-common__description'>{description}</Typography>
      <div className='modal-common__actions'>
        <Button
          variant='outlined'
          sx={{ minWidth: '212px', marginRight: 3}}
          onClick={onClose}
        >{t('actions__text__cancel')}</Button>
        <Button
          variant='contained'
          onClick={handleClickYes}
          color='error'
          startIcon={<DeleteIcon />}
          fullWidth
        >{buttonYesText}</Button>
      </div>
    </ModalBig>

  )
}

export default ModalAction
