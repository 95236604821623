import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import 'react-phone-input-2/lib/material.css'
import get from "lodash/get";
import { useNavigate } from "react-router-dom";

import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import {
  useCreateOrderMutation,
  useGetOrder, useSetCompletedByOrderIdMutation, useSummaryCompleted,
  useUpdateOrderMutation,
} from 'api/calc'

import Notification, {NOTIFICATION_TYPE} from '../../../../../../common/notification'
import {useCurrencies} from "../../../../../../../api/currencies";

import './editMainInfo.scss'

export enum ORDER_FORM {
  ORDER_NAME = 'order_name',
  COMMENT = 'comment',
  BASE_CURRENCY = 'base_currency',
}

export type OrderParams = {
  [ORDER_FORM.ORDER_NAME]: string
  [ORDER_FORM.COMMENT]: string
  [ORDER_FORM.BASE_CURRENCY]: string
}

type Props = {
    onSend(): void
    hasCancelButton?: boolean
    onClose?(): void
}

const EditMainInfo = ({ onSend, hasCancelButton, onClose } : Props) => {

  const { t } = useTranslation()

  const navigate = useNavigate()

  const { data: order } = useGetOrder()

  const currencies = useCurrencies();

  const [ showErrorNotification, setShowErrorNotification ] = useState<boolean | undefined>(false);

  const [ createOrder ] = useCreateOrderMutation()

  const [ updateOrder ] = useUpdateOrderMutation()

  const [ setCompleted ] = useSetCompletedByOrderIdMutation()

  const completedInfo = useSummaryCompleted();

  const defaultValues = {
    [ORDER_FORM.ORDER_NAME]: order?.[ORDER_FORM.ORDER_NAME],
    [ORDER_FORM.COMMENT]: order?.[ORDER_FORM.COMMENT],
    [ORDER_FORM.BASE_CURRENCY]: order?.[ORDER_FORM.BASE_CURRENCY],
  };

  const { register, handleSubmit, control, reset, formState: { errors, isValid } } = useForm<OrderParams>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    if (order?.num) {
      reset(defaultValues)
    }
  }, [ order?.num ])


  const onSubmit = handleSubmit(async values => {

    if (order?.num) {
      const result = await updateOrder({ id: order?.num, ...values})
      const id = get(result, 'data.num', '')
      if (!id) {
        setShowErrorNotification(true);
        return
      }
      onSend();
      setShowErrorNotification(false);
      return
    }

    const result = await createOrder(values)
    const id = get(result, 'data.num', '')
    if (!id) {
      setShowErrorNotification(true);
      return
    }
    onSend()

    setCompleted({
      orderId: id,
      ...completedInfo,
      common: true,
    })

    setShowErrorNotification(false);
    if (id) navigate(`${id}` )

  })

  return (
    <>
      <form
        id='main'
        className='main-form'
        onSubmit={onSubmit}
      >
        <TextField
          {...register(ORDER_FORM.ORDER_NAME, { required: t('common__text__isRequired'), maxLength: 200 })}
          fullWidth
          error={Boolean(errors[ORDER_FORM.ORDER_NAME])}
          helperText={errors[ORDER_FORM.ORDER_NAME]?.message}
          variant='outlined'
          label={t('calc__text__main__nameOfOrder')}
          InputLabelProps={{ shrink: true }}
          id={ORDER_FORM.ORDER_NAME}
        />
        <TextField
          {...register(ORDER_FORM.COMMENT)}
          fullWidth
          variant='outlined'
          label={t('calc__text__main__comment')}
          InputLabelProps={{ shrink: true }}
          id={ORDER_FORM.ORDER_NAME}
          multiline
          rows={2}
        />
        {/*<FormControl sx={{ width: '320px', boxSizing: 'border-box' }}>*/}
        {/*  <InputLabel id='base-currency-group-label'>{t('common__text__currency')}</InputLabel>*/}
        {/*  <Controller*/}
        {/*    name={ORDER_FORM.BASE_CURRENCY}*/}
        {/*    control={control}*/}
        {/*    render={({ field }) => (*/}
        {/*      <Select*/}
        {/*        {...field}*/}
        {/*        labelId='base-currency-group-label'*/}
        {/*        id='base-currency-group-label'*/}
        {/*        value={field.value}*/}
        {/*        label={t('common__text__currency')}*/}
        {/*        onChange={(event) => {*/}
        {/*          field.onChange(event.target.value);*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        {currencies.map((value) => <MenuItem value={value.code} key={value.code}>{`${value.code} — ${value.name}`} </MenuItem>)}*/}
        {/*      </Select>*/}
        {/*    )}*/}
        {/*  />*/}
        {/*</FormControl>*/}
        <div className='main-form__buttons'>
          {hasCancelButton && <Button
            variant='outlined'
            sx={{ minWidth: '212px', marginRight: 3}}
            onClick={onClose}
          >{t('actions__text__cancel')}</Button>}
          <Button
            variant='contained'
            fullWidth={!hasCancelButton}
            type='submit'
            startIcon={<CheckIcon />}
            disabled={!isValid}
            sx={{ minWidth: hasCancelButton ? '452px': 'auto'}}
          >{t('actions__text__saveTheDeliveryData')}</Button>
        </div>
        {showErrorNotification && <Notification type={NOTIFICATION_TYPE.ERROR} title={t('errors__message__save')} text={t('errors__message__tryLater5Min')} sx={{ marginTop: 4 }} />}
      </form>
    </>
  )
}

export default EditMainInfo
