import React from "react";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import TableSortLabel from "@mui/material/TableSortLabel";


interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: string ) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    orderBy: string;
    rowCount: number;
    headCells: HeaderCell[];
    count: number;
}


export type HeaderCell = {
    id: string
    numeric: boolean
    disablePadding: boolean
    label: React.ReactNode
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { onSelectAllClick, orderBy, numSelected, rowCount, onRequestSort, headCells, count } =
        props;

  const getArrowDirection = (headCell: HeaderCell) => {
    if (orderBy.includes(headCell.id)){
      return headCell.id === orderBy ? 'desc' : 'asc';
    }

    return undefined
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox' sx={{ width: '26px', padding: '0 0 0 8px' }}>
          <Checkbox
            color='primary'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && (numSelected === rowCount || numSelected === count)}
            onChange={onSelectAllClick}
            sx={{ padding: 0 }}
          />
        </TableCell>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={getArrowDirection(headCell)}
            sx={{ padding: index === 0 ? '16px 16px 16px 6px': '16px 16px 16px 28px' }}
          >
            <TableSortLabel
              active={orderBy.includes(headCell.id)}
              direction={getArrowDirection(headCell)}
              sx={{ fontWeight: '500' }}
              onClick={(event) => onRequestSort(event, headCell.id == orderBy ? `-${headCell.id}` : headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead
