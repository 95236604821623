import React, {ReactNode} from "react";
import {useTranslation} from "react-i18next";

import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import COLORS from "../../../../../../../../../styles/colors.scss";
import Completed from "../../../../../../../../common/completed";

import './../../summary.scss'

export type ItemsData = {
  label: string
  value: ReactNode
}

type Props =  {
    info: ItemsData[]
    handleSave?(): void
}

const CombinedCargo = ({info, handleSave}: Props) => {

  const { t } = useTranslation()

  return (<div className='summary-packaging__block-delivery'>
    <Typography variant='subtitle2' sx={{ fontSize: '16px', marginTop: 2, marginBottom: '18px', color: COLORS.BLACK_DARK }}>{t('calc__text__packaging__volumetricWeight')}</Typography>
    <Completed
      info={info}
      isFullRow
      noBackground
      noPadding
    />
    {handleSave && <Button
      variant='contained'
      fullWidth
      onClick={handleSave}
      startIcon={<CheckIcon/>}
      sx={{marginTop: 3}}
    >{t('actions__text__confirmDeliveryMethodCollectShipment')}</Button>}
  </div>
  )
}

export default CombinedCargo
