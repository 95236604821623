import InputAdornment from '@mui/material/InputAdornment'
import THEME_COLORS from 'styles/colors.scss'
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

export const API_URL = 'https://api.dita.systems'

export const enum ROUTES {
  REGISTER = '/register',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  WELCOME = '/welcome',
  CABINET = 'cabinet',
  CALCULATOR = 'calculator',
  CALCULATIONS = 'calculations',
  SETTINGS = 'settings',
  DICTIONARIES = 'dictionaries',
  CURRENCY = 'currency',
  LOCATIONS = 'locations',
  COUNTRIES = 'contries',
  CITIES = 'cities',
  INCOTERMS = 'incoterms',
  TRANSPORT_COMPANIES = 'transport_companies',
  PALLETS = 'pallets',
  INFORMERS = 'informers',
}

export const COLORS = {
  ORANGE: THEME_COLORS.ORANGE,
  BLUE: THEME_COLORS.BLUE,
  DARK: THEME_COLORS.DARK,
  BEIGE: THEME_COLORS.BEIGE,
  GRAY: THEME_COLORS.GRAY,
  DARK_GRAY: THEME_COLORS.DARK_GRAY,
  NEUTRAL_GRAY: THEME_COLORS.NEUTRAL_GRAY,
  LIGHT_GRAY: THEME_COLORS.LIGHT_GRAY,
  WHITE: THEME_COLORS.WHITE,
  GREEN: THEME_COLORS.GREEN,
  RED: THEME_COLORS.RED,
  LIME: THEME_COLORS.LIME,
  BORDER_GRAY: THEME_COLORS.BORDER_GRAY,
  STRONG_GRAY: THEME_COLORS.STRONG_GRAY,
  BLACK: THEME_COLORS.BLACK,
  BLACK_DARK: THEME_COLORS.BLACK_DARK,
  SLIGHTLY_GRAY: THEME_COLORS.SLIGHTLY_GRAY,
  LIGHT_TEXT_SECONDARY: THEME_COLORS.LIGHT_TEXT_SECONDARY,
  LIGHT_TEXT_DISABLED: THEME_COLORS.LIGHT_TEXT_DISABLED,
  LIGHT_TEXT_DISABLED_OPACITY: THEME_COLORS.LIGHT_TEXT_DISABLED_OPACITY,
  WARNING: THEME_COLORS.WARNING,
  LIGHT_BLUE: THEME_COLORS.LIGHT_BLUE,
  BLUE_FOCUS: THEME_COLORS.BLUE_FOCUS,
  MEDIUM_GRAY: THEME_COLORS.MEDIUM_GRAY
}

export const ADORNMENTS = {
  AMOUNT: <InputAdornment position='end'>шт.</InputAdornment>,
  PRICE: <InputAdornment position='end'>USD</InputAdornment>,
  WEIGHT: <InputAdornment position='end'>кг</InputAdornment>,
  SIZE: <InputAdornment position='end'>см</InputAdornment>,
  SIZE_SMALL: <InputAdornment position='end'>мм</InputAdornment>,
  VOLUME: <InputAdornment position='end'>м<sup>3</sup></InputAdornment>,
  CALENDAR: <InputAdornment position='end'><CalendarTodayIcon/></InputAdornment>,
  PERCENT: <InputAdornment position='end'>%</InputAdornment>,
}

export const ADORNMENT_LOCATION = <InputAdornment position='start'>
  <LocationOnIcon sx={{ color: COLORS.LIGHT_GRAY, marginLeft: '-2px', marginRight: '8px'}}/>
</InputAdornment>

export const MUI_LICENSE_KEY = 'eb624838b5e4d32e8ae0ba03c21c5d3bTz04ODc0MixFPTE3NDUwNzM1NDkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='

export const enum INFORMERS_CODES {
  VOLUME_WEIGHT = 'volume_weight',
  TNVED_CODE = 'tnved_code',
  ORIGIN_CERTIFICATE = 'origin_certificate',
  EXCISE = 'excise',
  DUTY_TAX = 'duty_tax',
  DELIVERY_CITY = 'delivery_city',
  CUSTOMS_PAYMENT = 'customs_payment',
  CUSTOMS_COST = 'customs_cost',
  CONTAINER_CNT = 'container_cnt',
  INVOICE_NUM = 'invoice_num',
}
