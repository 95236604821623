import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

type Props = {
    children: React.ReactNode[]
    hasEmptySelection?: boolean
}

const TableRowActions = ({ children, hasEmptySelection }: Props) => {

  const [ action, setAction ] = React.useState('');

  const { t } = useTranslation()

  useEffect(() => {
    if (hasEmptySelection){
      setAction('');
    }
  }, [ hasEmptySelection ])

  return (
    <FormControl
      sx={{ width: '344px', marginTop: 2 }}>
      <InputLabel id='action-select'>{t('actions__text__selectAction')}</InputLabel>
      <Select
        labelId='action-select'
        id='action-select'
        value={action}
        label={hasEmptySelection ? t('errors__message__noValue') : t('actions__text__selectAction')}
        onChange={({ target: { value } }) => setAction(value)}
      >
        {!hasEmptySelection && children}
      </Select>
    </FormControl>
  )
};

export default TableRowActions
