import React from "react";
import FormControl from '@mui/material/FormControl';
import { METHODS } from '../../index'
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

type Props =  {
    deliveryMethod?: number
    isModal?: boolean
    setDeliveryMethod(value?: number): void
}

const DeliveryType = ({deliveryMethod, setDeliveryMethod, isModal}: Props) => {

  const { t } = useTranslation()

  return <FormControl sx={{ width: '100%' }}>
    {isModal && <Typography variant='h6'>{t('calc__text__packaging__deliveryMethod')}</Typography>}
    <FormControl sx={{ width: '100%', boxSizing: 'border-box' }}>
      <InputLabel id='delivery-method-group-label'>{t('calc__text__packaging__deliveryMethod')}</InputLabel>
      <Select
        labelId='delivery-method-group-label'
        id='delivery-method-group-label'
        value={deliveryMethod}
        label={t('calc__text__packaging__deliveryMethod')}
        onChange={({ target: { value: type } }) => setDeliveryMethod(Number(type))}>
        <MenuItem value={METHODS.COMBINED_CARGO}>{t('calc__text__packaging__combinedCargo')}</MenuItem>
        <MenuItem value={METHODS.CONTAINERS}>{t('calc__text__packaging__containers')}</MenuItem>
      </Select>
    </FormControl>
  </FormControl>
}

export default DeliveryType
