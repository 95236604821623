import React, {useRef} from "react";

import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";
import debounce from "lodash/debounce";

type Props = {
  onSearch(query: string): void
}

const SearchField = ({onSearch}: Props) => {

  const { t } = useTranslation()

  const debouncedRequest = useRef(debounce(onSearch, 1000)).current

  return <TextField
    label={t('common__text__search')}
    fullWidth
    onChange={({ target: { value: query } }) => {
      debouncedRequest(query)
    }}
    InputLabelProps={{shrink: true}}
    InputProps={{
      endAdornment: <InputAdornment position='end'><SearchIcon/></InputAdornment>
    }}
  />
}

export default SearchField
