import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import React from "react";

import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

import {
  DECLARATION_FORM,
  DeclarationFormFields
} from "../../../../../customsDetails/components/customs_declaration/components/add_form";

type PreferencesCheckboxProps = {
    handleChangePreferences(checked: boolean): void
}

const PreferencesRadioButton = ({handleChangePreferences}: PreferencesCheckboxProps) => {

  const { register, watch, setValue } = useFormContext<DeclarationFormFields>();

  const { t } = useTranslation()

  const selectedValue = watch(DECLARATION_FORM.HAS_PREFERENCES);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value === 'true';
    setValue(DECLARATION_FORM.HAS_PREFERENCES, event.target.value);
    handleChangePreferences(newValue);
  };

  return  <FormControl>
    <RadioGroup
      row
      aria-labelledby='preferences-buttons-group-label'
      value={selectedValue}
      {...register(DECLARATION_FORM.HAS_PREFERENCES)}
      onChange={handleChange}
    >
      <FormControlLabel
        value='true'
        control={<Radio />}
        label={t('calc__text__customs__confirmPreferences')}
        sx={{ marginLeft: 0, marginRight: '20px' }}
      />
      <FormControlLabel
        value='false'
        control={<Radio />}
        label={t('calc__text__customs__refusePreferences')}
        sx={{ marginLeft: 0 }}
      />
    </RadioGroup>
  </FormControl>
}

export default PreferencesRadioButton
