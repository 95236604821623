import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import {
  useLocations,
  useLazyGetLocationQuery,
  useLazyGetLocationTypesQuery, useUpdateLocations,
} from "../../../../../api/dictionary";
import SearchField from "../../components/search_field";

import Header from './../../components/header';
import Preloader from "../../../../common/preloader";
import {INITIAL_DATA_REQUEST} from "../../constants";

import FilterLocation from './filters/filterLocation';

import CityTable from "./table";
import LocationsActions from "./actions";

import './location.scss';


const Locations = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch()

  const [ currentLocation, setCurrentLocation ] = useState<string[]>([])

  const [ showModalRemoveByDefault, setShowModalRemoveByDefault ] = useState(false)
  const [ showModalAddByDefault, setShowModalAddByDefault ] = useState(false)

  const [ hasLoadedFirstRequest, setHasLoadedFirstRequest ] = useState(false)

  const [ locationsRequest, { isLoading: isLoadingLocations } ] = useLazyGetLocationQuery()

  const [ getLocationTypes ] = useLazyGetLocationTypesQuery()

  const locationsParams = useLocations()?.params;

  const locationsRequestUpdate = (params?: DictionaryParamsInput) =>
    locationsRequest({...locationsParams, ...params});

  useEffect(() => {
    locationsRequest(INITIAL_DATA_REQUEST)
    return () => {
      // @ts-ignore
      dispatch(useUpdateLocations());
    };
  }, []);


  useEffect(() => {
    locationsRequestUpdate().then(() =>
      setHasLoadedFirstRequest(true)
    )
    getLocationTypes("");
  }, [])

  const citiesRequestSearch = (query: string) => locationsRequest({
    ...locationsParams,
    search: query,
  })

  const handleEdit = () => {
    setShowModalAddByDefault(true);
  }

  const handleOpenRemoveModal = () => setShowModalRemoveByDefault(true)

  const handleCloseModal = () => {
    setCurrentLocation([]);
    setShowModalRemoveByDefault(false);
    setShowModalAddByDefault(false);
  }

  const isLoadingStatus = isLoadingLocations && !hasLoadedFirstRequest;

  const handleFilterLocations = (params: string) => locationsRequestUpdate({ type__in: params})

  return (
    <>
      <div>
        <Header menuText={t('menu__text__locations')}/>
        {isLoadingStatus ? <Preloader/> : <div className='location-container'>
          <Card>
            <CardContent sx={{ padding: 0, '&:last-child' : { paddingBottom: 0 } }}>
              <div className='location-container__search-container'>
                <SearchField onSearch={citiesRequestSearch}  />
              </div>
              <CityTable
                handleEdit={handleEdit}
                handleRemove={handleOpenRemoveModal}
                handleLocationsRequest={locationsRequestUpdate}
                changeLocation={setCurrentLocation}
                currentLocations={currentLocation}
              />
            </CardContent>
          </Card>
        </div>}
      </div>
      {!isLoadingStatus && (
        <div>
          <LocationsActions
            locationsRequestUpdate={locationsRequestUpdate}
            isOpenRemoveByDefault={showModalRemoveByDefault}
            isOpenAddByDefault={showModalAddByDefault}
            currentLocations={currentLocation}
            onCloseModal={handleCloseModal}
          />
          <Card className='calculations__filters' sx={{ marginTop: 5 }}>
            <CardContent sx={{ padding: 0 }}>
              <Typography variant='h6' gutterBottom sx={{ padding: '16px 24px 10px'}}>
                {t('common__text__filters')}
              </Typography>
              <FilterLocation onChangeLocations={handleFilterLocations}/>
            </CardContent>
          </Card>
        </div>
      )}
    </>
  )
};

export default Locations
