import {useTranslation} from "react-i18next";
import React from "react";

import Typography from "@mui/material/Typography";

import {COLORS} from "../../../../../../../../../../../config/constants";

const PreferencesText = () => {

  const { t } = useTranslation()

  return (
    <Typography variant='body2' sx={{ color: COLORS.BLACK, marginBottom: '10px' }}>
      {`${t('calc__text__customs__areYouConfirmPreferences')}:`}
      <ul className='preferences__list'>
        <li>
          {t('calc__text__customs__theProductIsFullyManufacturedInTheSpecifiedCountryOfOrigin')}
        </li>
        <li>
          {t('calc__text__customs__directPurchase')}
        </li>
        <li>
          {t('calc__text__customs__directDelivery')}
        </li>
      </ul>
    </Typography>
  )
}

export default PreferencesText
