import React from "react";

import Typography from "@mui/material/Typography";

import {useTranslation} from "react-i18next";

import ModalBig from "../../../../../../common/modalBig";
import Link from "@mui/material/Link";

type Props = {
    isOpen: boolean
    onClose(): void
    info: Vat[]
}

const ModalVatRate = ( {isOpen, onClose, info}: Props) => {

  const { t } = useTranslation()

  const renderRates = (rates : Vat[]) => rates.map((rate, index) => (
    <div key={index}>
      <Typography variant='h6'>
        {t('common__text__vatRate')} {rate.ValueDetail?.ValueCount}%
      </Typography>
      <Typography variant='body1' sx={{ marginTop: 1 }}>
        {rate.Condition}
      </Typography>
      <Typography variant='body1' sx={{ marginBottom: 3}}>
        <Link href={rate.Link} target='_blank'>{rate.Document}</Link>
      </Typography>
    </div>
  ))

  return <ModalBig
    isOpen={isOpen}
    onClose={onClose}
    title={t('calc__text__customs__customsVATRates')}
  >
    {renderRates(info)}
  </ModalBig>
}

export default ModalVatRate
